import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { message } from "antd";

export function useDeleteInvestmentOffer() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(endpoints.TAKLIF + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.TAKLIF])
      message.success("Muvafaqqiyatli")
    },
    onError: () => {
      message.error("Xatolik")
    }
  })
}