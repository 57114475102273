import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { InvestorCommentModel } from "../utils/models/investorCommentModel";
import { message } from "antd";

export function useCreateComment() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
        const res = await $api.post(endpoints.INVESTOR_COMMENT, req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.INVESTOR_COMMENTS])
            message.success("Muvafaqqiyatli")
        },
        onError: () => {
            message.error("Xatolik")
        }
    })
}

export function useUpdateComment(id: number | undefined) {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
        const res = await $api.put(endpoints.INVESTOR_COMMENT + id + "/", req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.INVESTOR_COMMENTS])
            message.success("Muvafaqqiyatli")
        },
        onError: () => {
            message.error("Xatolik")
        }
    })
}

export function useDeleteComment() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
        const res = await $api.delete(endpoints.INVESTOR_COMMENT + id + "/")
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.INVESTOR_COMMENTS])
            message.success("Muvafaqqiyatli")
        },
        onError: () => {
            message.error("Xatolik")
        }
    })
}