import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { OfferModel } from "../utils/models/offerModel";
import { PaginationModel } from "../../../utils/models/PaginationModel";

export function useInvestorOffer(searchParams: string) {

    return useQuery<PaginationModel<OfferModel>>
        ([queryKeys.TAKLIF, searchParams], async () => {
            const res = await $api.get(endpoints.TAKLIF + searchParams)
            return res.data
        })
}