import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoint";
import { ProfileFormFields } from "../utils/models/profileFormFields";

// get profile
export const useGetProfile = () => {
  return useQuery<ProfileFormFields>(
    [queryKeys.PROFILE],
    async () => {
      const res = await $api.get(endpoints.USER)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  )
}