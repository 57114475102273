import React from "react"
import { Route, Routes } from "react-router-dom"
import { rootRoutes } from "./rootRoutes"
import { exactRouteFixer } from "../utils/helpers/exactRouteFixer"
import MainLayout from "../common/main-layout/MainLayout"
import { rootPaths } from "./rootPaths"
import Auth from "../features/auth/Index"
import CheckAuth from "./CheckAuth"
import RequireAuth from "../common/require-auth/RequireAuth"

const RootRouter = () => {
  return (
    <Routes>
      <Route element={<CheckAuth />}>
        <Route path={rootPaths.AUTH} element={<Auth />} />
      </Route>
      <Route element={<RequireAuth />}>
        <Route element={<MainLayout />}>
          {rootRoutes.map((item) => (
            <Route
              key={item.path}
              path={exactRouteFixer(item.path, item.isExact)}
              element={<item.element />}
            />
          ))}
        </Route>
      </Route>
      <Route path="*" element={<div>Not Found</div>} />
    </Routes>
  )
}

export default RootRouter
