import React from "react"

type Props = {
  color?: string
}

const CardPosIcon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.42969 15.8792L16.3797 3.9292"
        stroke={color}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6016 18.279L12.8016 17.079"
        stroke={color}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.293 15.5887L16.683 13.1987"
        stroke={color}
        strokeWidth="1.5"
        stroke-miterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.10127 10.239L10.7413 3.599C12.8613 1.479 13.9213 1.469 16.0213 3.569L20.9313 8.479C23.0313 10.579 23.0213 11.639 20.9013 13.759L14.2613 20.399C12.1413 22.519 11.0813 22.529 8.98127 20.429L4.07127 15.519C1.97127 13.419 1.97127 12.369 4.10127 10.239Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 21.9985H22.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CardPosIcon
