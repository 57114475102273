import React from "react"

const UploadIcon = () => {
  return (
    <svg
      width="231"
      height="166"
      viewBox="0 0 231 166"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="117" cy="80.5" r="80" fill="#A3C9FA" />
      <circle cx="33" cy="20.5" r="8" fill="#D1E4FC" />
      <circle cx="205" cy="126.5" r="6" fill="#D1E4FC" />
      <circle cx="32" cy="138.5" r="10" fill="#D1E4FC" />
      <circle cx="217" cy="38.5" r="10" fill="#D1E4FC" />
      <circle cx="198" cy="11.5" r="7" fill="#D1E4FC" />
      <g filter="url(#filter0_dd_94_18895)">
        <path
          d="M63.0783 124.714L115.53 100.255C118.163 99.0274 119.302 95.897 118.074 93.2636L87.0988 26.8362L66.1234 19.2018L23.2087 39.2133C20.5753 40.4413 19.4359 43.5716 20.6639 46.2051L56.0865 122.169C57.3145 124.802 60.4448 125.942 63.0783 124.714Z"
          fill="url(#paint0_linear_94_18895)"
        />
        <path
          d="M66.123 19.2017L87.0984 26.8361L72.7935 33.5066L66.123 19.2017Z"
          fill="#75ADF7"
        />
      </g>
      <g filter="url(#filter1_dd_94_18895)">
        <path
          d="M88.1519 106.839H146.025C148.931 106.839 151.287 104.484 151.287 101.578V28.2837L135.503 12.5H88.1519C85.2462 12.5 82.8906 14.8555 82.8906 17.7612V101.578C82.8906 104.484 85.2462 106.839 88.1519 106.839Z"
          fill="url(#paint1_linear_94_18895)"
        />
        <path d="M135.503 12.5L151.287 28.2837H135.503V12.5Z" fill="#75ADF7" />
      </g>
      <g filter="url(#filter2_dd_94_18895)">
        <path
          d="M117.91 100.224L170.362 124.682C172.995 125.91 176.126 124.771 177.354 122.138L208.329 55.7103L200.695 34.7349L157.78 14.7235C155.147 13.4955 152.016 14.6348 150.788 17.2683L115.366 93.2322C114.138 95.8657 115.277 98.996 117.91 100.224Z"
          fill="url(#paint2_linear_94_18895)"
        />
        <path
          d="M200.694 34.7349L208.329 55.7102L194.024 49.0398L200.694 34.7349Z"
          fill="#75ADF7"
        />
      </g>
      <g filter="url(#filter3_b_94_18895)">
        <rect
          x="89"
          y="88.5"
          width="56"
          height="56"
          rx="28"
          fill="#1977F2"
          fill-opacity="0.6"
        />
        <path
          d="M112.333 121.167L117 116.5M117 116.5L121.666 121.167M117 116.5V127M126.333 122.033C127.758 120.856 128.666 119.076 128.666 117.083C128.666 113.54 125.793 110.667 122.25 110.667C121.995 110.667 121.756 110.534 121.627 110.314C120.105 107.732 117.297 106 114.083 106C109.251 106 105.333 109.918 105.333 114.75C105.333 117.16 106.308 119.343 107.884 120.926"
          stroke="white"
          strokeWidth="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_dd_94_18895"
          x="-1.55957"
          y="12.5312"
          width="141.857"
          height="154.406"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_94_18895"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_18895"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_94_18895"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_18895"
            result="effect2_dropShadow_94_18895"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_18895"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_dd_94_18895"
          x="62.8906"
          y="12.5"
          width="108.396"
          height="134.339"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_94_18895"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_18895"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_94_18895"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_18895"
            result="effect2_dropShadow_94_18895"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_18895"
            result="shape"
          />
        </filter>
        <filter
          id="filter2_dd_94_18895"
          x="93.1426"
          y="12.5"
          width="141.857"
          height="154.406"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect1_dropShadow_94_18895"
          />
          <feOffset dy="8" />
          <feGaussianBlur stdDeviation="4" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.03 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_94_18895"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feMorphology
            radius="4"
            operator="erode"
            in="SourceAlpha"
            result="effect2_dropShadow_94_18895"
          />
          <feOffset dy="20" />
          <feGaussianBlur stdDeviation="12" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.08 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_94_18895"
            result="effect2_dropShadow_94_18895"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect2_dropShadow_94_18895"
            result="shape"
          />
        </filter>
        <filter
          id="filter3_b_94_18895"
          x="81"
          y="80.5"
          width="72"
          height="72"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="4" />
          <feComposite
            in2="SourceAlpha"
            operator="in"
            result="effect1_backgroundBlur_94_18895"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_backgroundBlur_94_18895"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_94_18895"
          x1="59.9968"
          y1="124.919"
          x2="18.1972"
          y2="49.509"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6BCD9" />
          <stop offset="1" stopColor="#93C2FF" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_94_18895"
          x1="85.2725"
          y1="105.723"
          x2="79.2587"
          y2="19.7131"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6BCD9" />
          <stop offset="1" stopColor="#93C2FF" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_94_18895"
          x1="115.773"
          y1="97.9951"
          x2="146.672"
          y2="17.5024"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A6BCD9" />
          <stop offset="1" stopColor="#93C2FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default UploadIcon
