import React, { useState } from "react"
import styles from "./index.module.scss"
import CreateFossil from "./components/create-fossil/CreateFossil"
import { useGetFossils } from "./service/queries"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import { useDeleteFossil } from "./service/mutation"
import { FossilModel } from "./utils/models/FossilsModel"
import { Button, Empty, Input, Pagination } from "antd"
import { useQueryParam } from "../../hooks/useQueryParams"
import itemRender from "../../utils/helpers/ItemReader"
import SearchIcon from "../../assets/icons/SearchIcon"
import PlusIcon from "../../assets/icons/PlusIcon"
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon"
import { useNavigate } from "react-router-dom"

const Index = () => {
  const { searchParams, searchParamsString, appendMultipleDifferent } =
    useQueryParam<any, any>()
  const [search, setSearch] = useState(searchParams.search)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [drawerData, setDrawerData] = useState<FossilModel | undefined>()
  const { data } = useGetFossils(searchParamsString)
  const navigate = useNavigate()

  const deleteFossil = useDeleteFossil()

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  //handleDeleteFossil
  const handleDeleteFossil = (id: number) => {
    return deleteFossil.mutateAsync(id)
  }

  //handleUpdate
  const handleUpdate = (render: FossilModel) => {
    setDrawerData(render)
    handleOpenDrawer()
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const changeSearch = (search: string) => {
    setSearch(search)
    appendMultipleDifferent(["search"], [search])
  }

  return (
    <div className={styles.container}>
      <div className={styles.container_header}>
        <ArrowLeftIcon onClick={() => navigate(-1)} />
        <span>Qazilmalar</span>
      </div>
      <div className={styles.newsTable}>
        <div className={styles.newsTable_header}>
          <Input
            prefix={<SearchIcon />}
            placeholder="Search for trades"
            value={search}
            onChange={(e) => changeSearch(e.target.value)}
          />
          <Button onClick={handleOpenDrawer}>
            <PlusIcon />
            <span>Yangi qo'shish</span>
          </Button>
        </div>
        <div className={styles.content}>
          {data?.data?.map((item, index) => {
            return (
              <div key={index} className={styles.product}>
                <div className={styles.productIn}>
                  <div className={styles.product_img}>
                    {item?.image ? (
                      <img
                        src={`${process.env.REACT_APP_API_URL}${item?.image}`}
                        alt=""
                      />
                    ) : (
                      <Empty />
                    )}
                  </div>
                  <div className={styles.product_content}>
                    <p>{item?.name_uz}</p>
                    <div className={styles.product_content_desc}>
                      {item?.text_uz}
                    </div>
                    <div className={styles.product_content_bottom}>
                      <span className={styles.text}>
                        Mavjud konlar: <span>{item?.exist_count}</span>
                      </span>
                      <span className={styles.text}>
                        Amaldagi konlar: <span>{item?.valid_count}</span>
                      </span>
                      <span className={styles.text}>
                        Foydalanilmayotgan konlar: <span>{item?.invalid_count}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div className={styles.product_actions}>
                  <span onClick={() => handleUpdate(item)}>Tahrirlash</span>
                  <DeleteConfirm
                    text={`Haqiqatdan ham ${item?.name_uz} ni o'chirmoqchimisiz?`}
                    onConfirm={() => handleDeleteFossil(item?.id)}
                    isLoading={deleteFossil?.isLoading}
                  >
                    <span>O'chirish</span>
                  </DeleteConfirm>
                </div>
              </div>
            )
          })}
        </div>
        <Pagination
          onChange={(page) => changePage(page, 4)}
          current={data?.current_page}
          total={data?.total}
          itemRender={itemRender}
          pageSize={4}
          hideOnSinglePage
        />
      </div>
      <CreateFossil
        drawerData={drawerData}
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setDrawerData={setDrawerData}
      />
    </div>
  )
}

export default Index
