import React, { useEffect, useState } from "react"
import EditLanguage from "../languages/EditLanguage"
import FileIcon from "../../../../assets/icons/FileIcon"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import Map from "./Map"
import MapUpdate from "./MapUpdate"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import { ValidateErrorEntity } from "rc-field-form/es/interface"
import { Button, Drawer, Form, Input, Select, Tabs, Upload } from "antd"
import { InvestmentsModel } from "../../utils/models/investmentsModel"
import { CreateInvestModel } from "../../utils/models/createInvestModel"
import { useInvestmentCategory1, useInvestmentTags1 } from "../../service/queries"
import { useCreateInvestments, useUpdateInvestments } from "../../service/mutation"
import { localeFormatter } from "../../../../hooks/localeFormatter"
import { parseLocaledString } from "../../../../utils/helpers/parceLocaledString"
import { useGetCities } from "../../../fossils/service/queries"

import styles from "./drawers.module.scss"
import TrashIcon from "../../../../assets/icons/TrashIcon"

const { Option } = Select
const { Dragger } = Upload

type Props = {
  incomeOpen: boolean
  modalData: InvestmentsModel | undefined
  setIncomeOpen: React.Dispatch<React.SetStateAction<boolean>>
  setModalData: React.Dispatch<React.SetStateAction<InvestmentsModel | undefined>>
}

const IncomeInvestDrawer: React.FC<Props> = ({
  incomeOpen,
  setIncomeOpen,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const { data: tags } = useInvestmentTags1()
  const { data: category } = useInvestmentCategory1()
  const { data: cities } = useGetCities()
  const [activeTab, setActiveTab] = useState("uz")
  const [powerVal, setPowerVal] = useState("dona")
  const [countVal, setCountVal] = useState("mln m3")
  const createInvestments = useCreateInvestments()
  const updateInvestments = useUpdateInvestments(modalData?.id)

  console.log(modalData?.images)

  //handleClose
  const handleClose = () => {
    setIncomeOpen(false)
    setModalData(undefined)
    form.resetFields()
  }

  //handleChangeTabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  //onFinish
  const onFinish = (values: CreateInvestModel) => {
    const sendData = {
      ...values,
      author: 1,
      summa: parseLocaledString(values?.summa),
      quvvati: parseLocaledString(values?.quvvati),
      irr: values?.irr.includes(".") ? values?.irr : String(`${values?.irr}.0`),
      qiymati: values?.qiymati.includes(".")
        ? values?.qiymati
        : parseLocaledString(String(`${values?.qiymati}.0`)),
      npv: values?.npv.includes(".")
        ? values?.npv
        : parseLocaledString(String(`${values?.npv}.0`)),
    }
    const formData = new FormData()
    const images = values.images?.fileList?.map((item) => item?.originFileObj)

    Object.keys(sendData).forEach((element) => {
      if (
        sendData[element as keyof typeof sendData] &&
        element !== "images" &&
        element !== "tag" &&
        element !== "location"
      ) {
        formData.append(
          element,
          sendData[element as keyof typeof sendData] as string
        )
      }
    })

    images &&
      images?.forEach((item: any) => {
        formData.append("images", item)
      })
    modalData?.images &&
      modalData?.images?.forEach((item: any) => {
        formData.append("images", item)
      })
    values.tag?.forEach((tag) => {
      formData.append("tag", String(tag))
    })
    values.location?.forEach((location) => {
      formData.append("location", String(location))
    })

    if (modalData) {
      updateInvestments.mutateAsync(formData).then(handleClose)
    } else {
      createInvestments.mutateAsync(formData).then(handleClose)
    }
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0]?.name[0] as string
    const tabs = ["uz", "ru", "xi", "en", "ar"]
    const errorTab = name.slice(name.length - 2)

    if (tabs.includes(errorTab)) {
      setActiveTab(errorTab)
    }

    setTimeout(() => {
      document
        .getElementById(name)
        ?.scrollIntoView({ block: "center", behavior: "smooth" })
    }, 200)
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        ...modalData,
        category: modalData?.category.id,
        min_summa: Number(modalData?.min_summa).toLocaleString("ru"),
        summa: Number(modalData?.summa).toLocaleString("ru"),
        quvvati: Number(modalData?.quvvati).toLocaleString("ru"),
        // qiymati: Number(modalData?.qiymati).toLocaleString("ru"),
        job_count: Number(modalData?.job_count).toLocaleString("ru"),
        dpp: Number(modalData?.dpp).toLocaleString("ru"),
        // npv: Number(modalData?.npv).toLocaleString("ru"),
        market_export: Number(modalData?.market_export).toLocaleString("ru"),
        market_import: Number(modalData?.market_import).toLocaleString("ru"),
        images: modalData?.images?.map((item) => {
          return { originFileObj: item }
        }),
      })
    }
  }, [modalData])

  const handlePriceFields = (field: string) => {
    const value = form.getFieldValue([field])

    form.setFieldsValue({
      [field]: localeFormatter(value),
    })
  }

  return (
    <Drawer
      open={incomeOpen}
      onClose={handleClose}
      title={
        <div className={styles.incomeTitle}>
          <span>Investitsiya qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
      width={1100}
      className={styles.incomeDrawer}
    >
      <div className={styles.incomeDrawer_tabs}>
        <Form
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Tabs
            activeKey={activeTab}
            items={[
              {
                key: "uz",
                label: "O'zbek",
                children: <EditLanguage language="uz" />,
                forceRender: true,
              },
              {
                key: "ru",
                label: "Rus",
                children: <EditLanguage language="ru" />,
                forceRender: true,
              },
              {
                key: "en",
                label: "Ingliz",
                children: <EditLanguage language="en" />,
                forceRender: true,
              },
              {
                key: "xi",
                label: "Xitoy",
                children: <EditLanguage language="xi" />,
                forceRender: true,
              },
              {
                key: "ar",
                label: "Arab",
                children: <EditLanguage language="ar" />,
                forceRender: true,
              },
            ]}
            onChange={handleChangeTabs}
          />
          {modalData ? (
            <MapUpdate
              open={incomeOpen}
              formInstance={form}
              coords={modalData?.location?.map((item: string) => +item) as number[]}
            />
          ) : (
            <Map open={incomeOpen} formInstance={form} />
          )}
          <div className={styles.incomeDrawer_source}>
            <Form.Item label="Biznes shakli" name="biznes">
              <Tabs
                defaultActiveKey="1"
                items={[
                  { label: "Start-up", key: "1" },
                  { label: "Korxona", key: "2" },
                ]}
              />
            </Form.Item>
            <Form.Item label="Source link" name="link">
              <Input addonBefore="https://" />
            </Form.Item>
          </div>
          <div className={styles.incomeDrawer_category}>
            <Form.Item
              label="Kategoriyalar"
              name="category"
              className={styles.incomeDrawer_category_first}
              rules={[{ required: true, message: "" }]}
            >
              <Select>
                {category?.data?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item.name_uz}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Tag" name="tag">
              <Select mode="multiple">
                {tags?.data?.map((item) => (
                  <Option value={item.id} key={item.id}>
                    {item?.name_uz}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div className={styles.incomeDrawer_summa}>
            <Form.Item
              label="Investitsiya summasi"
              name="summa"
              rules={[{ required: true, message: "" }]}
            >
              <Input onChange={() => handlePriceFields("summa")} />
            </Form.Item>
            <Form.Item
              label="Valyuta"
              name="valyuta"
              rules={[{ required: true, message: "" }]}
            >
              <Select>
                <Option value={"uzs"}>UZS</Option>
                <Option value={"usd"}>USD</Option>
              </Select>
            </Form.Item>
          </div>
          <div className={styles.incomeDrawer_summa}>
            <Form.Item name={"quvvati"} label="Loyiha quvvati">
              <Input
                onChange={() => handlePriceFields("quvvati")}
                addonAfter={
                  <Form.Item name={"unit_quvvati"}>
                    <Select value={powerVal} onChange={(e) => setPowerVal(e)}>
                      <Option value={"dona"}>dona</Option>
                      <Option value="kv_metr">m2</Option>
                      <Option value="kub_metr">m3</Option>
                      <Option value="tonna">tonna</Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
            <Form.Item name={"qiymati"} label="Loyiha qiymati">
              <Input
                onChange={() => handlePriceFields("qiymati")}
                addonAfter={
                  <Form.Item name={"unit_qiymati"}>
                    <Select value={countVal} onChange={(e) => setCountVal(e)}>
                      <Option value={"mln m3"}>mln m3</Option>
                      <Option value="ming m3">ming m3</Option>
                    </Select>
                  </Form.Item>
                }
              />
            </Form.Item>
          </div>
          <div className={styles.incomeDrawer_summa}>
            <Form.Item name={"irr"} label={"IRR"}>
              <Input defaultValue={0} onChange={() => handlePriceFields("irr")} />
            </Form.Item>

            <Form.Item name={"npv"} label="NPV">
              <Input onChange={() => handlePriceFields("npv")} addonAfter="mln $" />
            </Form.Item>
          </div>
          <div className={styles.incomeDrawer_summa}>
            <Form.Item name={"dpp"} label="DPP">
              <Input addonAfter="oy" />
            </Form.Item>
            <Form.Item name={"job_count"} label="Ish o'rni">
              <Input
                onChange={() => handlePriceFields("job_count")}
                addonAfter="ta"
              />
            </Form.Item>
          </div>
          <div className={styles.incomeDrawer_summa}>
            <Form.Item label="Marketing rejasi">
              <div className={styles.marketing}>
                <Form.Item name={"market_export"} label="Export - ">
                  <Input
                    onChange={() => handlePriceFields("market_export")}
                    addonAfter="%"
                  />
                </Form.Item>

                <Form.Item name={"market_import"} label="Ichki bozor - ">
                  <Input
                    onChange={() => handlePriceFields("market_import")}
                    addonAfter="%"
                  />
                </Form.Item>
              </div>
            </Form.Item>
            <Form.Item name={"city"} label="Loyiha manzili">
              <Select>
                {cities?.map((item) => (
                  <Option key={item?.id} value={item?.id}>
                    {item?.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <Form.Item label="Rasm" name="images">
            <Dragger
              className={styles.innovationUpload}
              iconRender={() => <FileIcon />}
              accept=".png, .svg, .jpg, .jpeg, .gif, .ico"
              customRequest={() => null}
            >
              <UploadIcon />
              <p className="ant-upload-text">
                <span>Click to upload</span> or drag and drop
              </p>
              <p className="ant-upload-hint">
                SVG, PNG, JPG or GIF (max. 800x400px)
              </p>
            </Dragger>
          </Form.Item>
          {modalData?.images && (
            <div className={styles.incomeDrawer_tabs_images}>
              {modalData?.images?.map((item: any, index) => (
                <div key={index} className={styles.incomeDrawer_tabs_images_item}>
                  <FileIcon />
                  <span>{item.replace("/media/investitsiyalar/", "")}</span>
                  <span
                    className={styles.trush}
                    onClick={() => {
                      setModalData((prev) => ({
                        ...modalData,
                        images: modalData?.images?.filter((el, i) => index !== i),
                      }))
                    }}
                  >
                    <TrashIcon />
                  </span>
                </div>
              ))}
            </div>
          )}
          <div className={styles.incomeDrawer_btns}>
            <Button onClick={handleClose}>Bekor qilish</Button>
            <Button
              htmlType="submit"
              loading={createInvestments.isLoading || updateInvestments.isLoading}
            >
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </Drawer>
  )
}

export default IncomeInvestDrawer
