import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { PaginationModel } from "../../../utils/models/PaginationModel";
import { FaqModel } from "../utils/models/FaqModel";
import { FaqClassModel } from "../utils/models/FaqClassModel";

export function useGetFaq(page: string) {
  return useQuery<PaginationModel<FaqModel>>
    ([queryKeys.FAQ, page], async () => {
      const res = await $api.get(`${endpoints.FAQ}?page=${page}`)
      return res.data
    })
}

export function useGetFaqClass(searchParams: string) {

  return useQuery<PaginationModel<FaqClassModel>>
    ([queryKeys.FAQ_CLASS, searchParams], async () => {
      const res = await $api.get(endpoints.FAQ_CLASS + searchParams)
      return res.data
    })
}