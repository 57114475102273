import React, { useState } from "react"
import { Button, Input, Skeleton, Table } from "antd"
import { useQueryParam } from "../../../../hooks/useQueryParams"
import { TagsModel } from "../../../investments/utils/models/tagsModel"
import { useInvestmentTags } from "../../../investments/service/queries"
import { tagsIcons } from "./TagIcons"
import { useDeleteTag } from "../../services/mutation"
import SearchIcon from "../../../../assets/icons/SearchIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import TagsDrawer from "../drawers/TagsDrawer"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import itemRender from "../../../../utils/helpers/ItemReader"

import styles from "../admins/admins.module.scss"

const TagsTable: React.FC = () => {
  const { searchParams, searchParamsString, appendMultipleDifferent } =
    useQueryParam<any, any>()
  const { data, isLoading } = useInvestmentTags(searchParamsString)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<TagsModel | undefined>()
  const deleteTag = useDeleteTag()
  const [search, setSearch] = useState(searchParams.search)

  const changeSearch = (value: string) => {
    setSearch(value)
    appendMultipleDifferent(["search"], [value])
  }

  //handleOpen
  const handleOpen = () => {
    setOpenDrawer(true)
  }

  //handleUpdate
  const handleUpdate = (render: TagsModel) => {
    handleOpen()
    setModalData({ ...render, icon: render.icon })
  }

  //handleDelete
  const handleDelete = (id: number) => {
    return deleteTag.mutateAsync(id)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Icon",
      key: "color",
      render: (render: TagsModel) => (
        <div className={styles.tagsIcon}>
          <div
            style={{ backgroundColor: render.color }}
            className={styles.tagColor}
          ></div>
          <div className={styles.tagsIcon_icon}>
            {tagsIcons[render?.icon]?.icon(render?.color)}
          </div>
          <span>{render.icon}</span>
        </div>
      ),
      width: 496,
    },
    {
      title: "Tag nomi",
      key: "icon",
      render: (render: TagsModel) => <span>{render.name_uz}</span>,
      width: 496,
    },
    {
      title: "",
      key: "actions",
      render: (render: TagsModel) => (
        <div className={styles.actions}>
          <DeleteConfirm
            text={`${render.icon} ni o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(render?.id)}
          >
            <span className={styles.actions_delete}>O'chirish</span>
          </DeleteConfirm>
          <span
            className={styles.actions_update}
            onClick={() => handleUpdate(render)}
          >
            Tahrirlash
          </span>
        </div>
      ),
    },
  ]

  return (
    <div className={styles.adminTable}>
      <div className={styles.adminTable_header}>
        <Input
          placeholder="Qidirmoq"
          prefix={<SearchIcon />}
          value={search}
          onChange={(e) => changeSearch(e.target.value)}
        />
        <Button onClick={handleOpen}>
          <PlusIcon />
          <span>Yangi qo'shish</span>
        </Button>
      </div>
      {!isLoading ? (
        <Table
          columns={columns}
          dataSource={data?.data}
          pagination={{
            onChange: (page) => changePage(page, 10),
            current: data?.current_page,
            total: data?.total,
            itemRender: itemRender,
            hideOnSinglePage: true,
          }}
        />
      ) : (
        <Skeleton active />
      )}
      <TagsDrawer
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        modalData={modalData}
        setModalData={setModalData}
      />
    </div>
  )
}

export default TagsTable
