import React, { useState } from "react"
import BodyLayout from "../../common/body-layout/BodyLayout"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import itemRender from "../../utils/helpers/ItemReader"
import OfferDrawer from "./components/offer-drawer/OfferDrawer"
import { Skeleton, Table } from "antd"
import { useInvestorOffer } from "./services/queries"
import { OfferModel } from "./utils/models/offerModel"
import { useDeleteInvestmentOffer } from "./services/mutation"
import { useQueryParam } from "../../hooks/useQueryParams"

import styles from "./offer.module.scss"

const Index: React.FC = () => {
  const { searchParamsString, appendMultipleDifferent } = useQueryParam()
  const { data, isLoading } = useInvestorOffer(searchParamsString)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<OfferModel | undefined>()
  const deleteOffer = useDeleteInvestmentOffer()

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }
  const handleDeleteOffer = (id: number) => {
    return deleteOffer.mutateAsync(id)
  }

  const handleViewData = (render: OfferModel) => {
    setModalData(render)
    handleOpenDrawer()
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Qayerga kiritmoqchi",
      key: "title",
      render: (render: OfferModel) => (
        <span className={styles.offerTable_name}>{render.company}</span>
      ),
    },
    {
      title: "Investor nomi",
      key: "name",
      render: (render: OfferModel) => <span>{render.name}</span>,
    },
    {
      title: "Kompaniya",
      dataIndex: "company",
      key: "company",
    },
    {
      title: "Telefon raqami",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Email address",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Izoh",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "",
      key: "actions",
      render: (render: OfferModel) => (
        <div className={styles.offerTable_action}>
          <span onClick={() => handleViewData(render)}>Ko'rish</span>
          <DeleteConfirm
            text={`${render.name} ni o'chirmoqchimisiz?`}
            onConfirm={() => handleDeleteOffer(render.id)}
            isLoading={deleteOffer?.isLoading}
          >
            <span>O'chirish</span>
          </DeleteConfirm>
        </div>
      ),
    },
  ]

  return (
    <BodyLayout title="Takliflar">
      {!isLoading ? (
        <Table
          className={styles.offerTable}
          columns={columns}
          dataSource={data?.data}
          pagination={{
            onChange: (page) => changePage(page, 10),
            current: data?.current_page,
            total: data?.total,
            itemRender: itemRender,
            hideOnSinglePage: true,
          }}
        />
      ) : (
        <Skeleton active />
      )}

      <OfferDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        setModalData={setModalData}
        modalData={modalData}
      />
    </BodyLayout>
  )
}

export default Index
