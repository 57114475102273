import { Col, Form, Input, Row, Select } from "antd"
import React, { useState } from "react"
import TextEditor from "../../../../common/text-editor/TextEditor"

import styles from "../drawer/drawers.module.scss"
import { useLocation } from "react-router-dom"
import { rootPaths } from "../../../../routing/rootPaths"
import { useGetCities } from "../../../fossils/service/queries"

type Props = {
  language: string
}

const { Option } = Select

const EditLanguage: React.FC<Props> = ({ language }) => {
  const location = useLocation()
  const pathName = location?.pathname
  const [value, setValue] = useState("")

  const { data } = useGetCities()

  return (
    <div className={styles.incomeDrawer_editing}>
      {pathName === rootPaths.FOSSILS ? (
        <div style={{ marginTop: 16 }}>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item
                label="Nomi"
                name={`name_${language}`}
                rules={[{ required: true, message: "" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Shahar, tuman"
                name="city"
                rules={[{ message: "", required: true }]}
              >
                <Select>
                  {data?.map((item) => (
                    <Option key={item?.id}>{item?.name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            label="Qisqa tarif"
            name={`text_${language}`}
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
        </div>
      ) : pathName === rootPaths?.SANOAT_TARMOQLARI ? (
        <div>
          <Form.Item
            label="Nomi"
            name={`name_${language}`}
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Qisqa tarif"
            name={`text_${language}`}
            // rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
        </div>
      ) : (
        <Form.Item
          label="Nomi"
          name={`name_${language}`}
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
      )}
      <Form.Item
        label="Izoh"
        name={
          pathName === rootPaths.FOSSILS || pathName === rootPaths?.SANOAT_TARMOQLARI
            ? `description_${language}`
            : `malumot_${language}`
        }
      >
        <TextEditor value={value} onChange={setValue} />
      </Form.Item>
    </div>
  )
}

export default EditLanguage
