import React, { useEffect } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import { Button, Drawer, Form, Input, Upload } from "antd"
import { InvestorCommentModel } from "../../utils/models/investorCommentModel"
import { useCreateComment, useUpdateComment } from "../../services/mutation"

import styles from "./commentDrawer.module.scss"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import FileIcon from "../../../../assets/icons/FileIcon"
import { UploadChangeParam, UploadFile } from "antd/es/upload"

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setModalData: React.Dispatch<
    React.SetStateAction<InvestorCommentModel | undefined>
  >
  modalData: InvestorCommentModel | undefined
  openDrawer: boolean
}

const { Dragger } = Upload

const CommentDrawer: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const createComment = useCreateComment()
  const updateComment = useUpdateComment(modalData?.id)

  //handleCloseDrawer
  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setModalData(undefined)
    form.resetFields()
  }

  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  //onFinish
  const onFinish = (values: InvestorCommentModel) => {
    const formData = new FormData()
    let key: keyof typeof values
    for (key in values) {
      if (values[key] && key !== "image") {
        formData.append(key, values[key] as string)
      }
    }
    if (values.image && typeof values.image !== "string") {
      formData.append("image", values.image)
    }
    if (modalData) {
      updateComment.mutateAsync(formData).then(handleCloseDrawer)
    } else {
      createComment.mutateAsync(formData).then(handleCloseDrawer)
    }
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData })
    }
  }, [modalData])

  return (
    <Drawer
      open={openDrawer}
      onClose={handleCloseDrawer}
      closeIcon={false}
      title={
        <div className={styles.dwawerTitle}>
          <span>Izoh qo'shish</span>
          <CloseIcon onClick={handleCloseDrawer} />
        </div>
      }
      width={544}
    >
      <Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off">
        <Form.Item
          label="Kompaniya"
          name="company"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Investor nomi"
          name="investor_name"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          className={styles.comment}
          label="Izoh"
          name="comment"
          rules={[{ required: true, message: "" }]}
        >
          <Input.TextArea
            placeholder="Izoh qo'shing..."
            className={styles.comment_textarea}
          />
        </Form.Item>
        <Form.Item label="Rasm" name="image" getValueFromEvent={handleFileFromEvent}>
          <Dragger
            className={styles.innovationUpload}
            multiple
            iconRender={() => <FileIcon />}
            accept=".png, .svg, .jpg, .jpeg, .gif, .ico"
            customRequest={() => null}
            maxCount={1}
          >
            <UploadIcon />
            <p className="ant-upload-text">
              <span>Click to upload</span> or drag and drop
            </p>
            <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </Dragger>
        </Form.Item>
        <div className={styles.commentBtns}>
          <Button onClick={handleCloseDrawer}>Bekor qilish</Button>
          <Button htmlType="submit" loading={createComment.isLoading}>
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default CommentDrawer
