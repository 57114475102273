import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IndustryModel } from "../utils/models/IndustryModel";
import $api from "../../../services/RequestService";
import { endPoints } from "../utils/constants/endPoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { message } from "antd";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";

export function useCreateIndustry() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
    const res = await $api.post(endPoints.INDUSTRY, req)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.INDUSTRY])
      message.success("Muvaffaqqiyatli")
    }
  })
}

export function useUpdateIndustry(id: number | undefined) {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FormData>(async (data) => {
    const res = await $api.patch(endPoints.INDUSTRY + id + "/", data)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.INDUSTRY])
      message.success("Muvaffaqqiyatli")
    }
  })
}

export function useDeleteIndustry() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(endPoints.INDUSTRY + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.INDUSTRY])
      message.success("Muvaffaqqiyatli")
    }
  })
}