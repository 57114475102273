import React, { useState } from "react"
import { NewsModel } from "../../utils/models/newsModel"
import { useGetYangiliklar } from "../../services/queries"
import { useDeleteYangilik } from "../../services/mutation"
import { Button, Input, Pagination, Skeleton } from "antd"
import InnovationDrawers from "../drawers/InnovationDrawers"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import SearchIcon from "../../../../assets/icons/SearchIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import dayjs from "dayjs"
import itemRender from "../../../../utils/helpers/ItemReader"

import styles from "../../innovation.module.scss"
import { useQueryParam } from "../../../../hooks/useQueryParams"

const NewsTable = () => {
  const [modalData, setModalData] = useState<NewsModel | undefined>()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { searchParams, searchParamsString, appendMultipleDifferent } =
    useQueryParam<any, any>()
  const { data, isLoading } = useGetYangiliklar(searchParamsString)
  const deleteYangilik = useDeleteYangilik()
  const [search, setSearch] = useState(searchParams.search)
  const changeSearch = (search: string) => {
    setSearch(search)
    appendMultipleDifferent(["search"], [search])
  }

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  //handleUpdate
  const handleUpdate = (render: NewsModel) => {
    setModalData(render)
    handleOpenDrawer()
  }

  //handleDeleteYangilik
  const handleDeleteYangilik = (slug: string) => {
    return deleteYangilik.mutateAsync(slug)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  return (
    <div className={styles.newsTable}>
      <div className={styles.newsTable_header}>
        <Input
          prefix={<SearchIcon />}
          placeholder="Search for trades"
          value={search}
          onChange={(e) => changeSearch(e.target.value)}
        />
        <Button onClick={handleOpenDrawer}>
          <PlusIcon />
          <span>Yangi qo'shish</span>
        </Button>
      </div>
      {!isLoading ? (
        <div className={styles.innovation}>
          {data?.data?.map((item) => (
            <div className={styles.innovation_child} key={item?.id}>
              <div className={styles.innovation_child_left}>
                {item?.files.length !== 0 ? (
                  <img src={`${process.env.REACT_APP_API_URL}${item?.files[0]}`} />
                ) : (
                  <div className={styles.innovation_child_img}></div>
                )}
                <div className={styles.innovation_child_left_text}>
                  <h5>{item?.name_uz}</h5>
                  <span>{dayjs(item?.date).format("MMM DD, YYYY")}</span>
                </div>
              </div>
              <div className={styles.innovation_child_right}>
                <DeleteConfirm
                  text={`${item?.name_uz} ni o'chirmoqchimisiz?`}
                  onConfirm={() => handleDeleteYangilik(item?.slug)}
                >
                  <span className={styles.innovation_child_right_delete}>
                    O'chirish
                  </span>
                </DeleteConfirm>
                <span
                  className={styles.innovation_child_right_update}
                  onClick={() => handleUpdate(item)}
                >
                  Tahrirlash
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton active />
      )}

      <Pagination
        onChange={(page) => changePage(page, 4)}
        current={data?.current_page}
        total={data?.total}
        itemRender={itemRender}
        pageSize={4}
        hideOnSinglePage
      />
      <InnovationDrawers
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        modalData={modalData}
        setModalData={setModalData}
      />
    </div>
  )
}

export default NewsTable
