import React from "react"
import { Tabs } from "antd"
import TagsTable from "./components/tags/TagsTable"
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon"
import AdminsTable from "./components/admins/AdminsTable"
import CategoryTable from "./components/category/CategoryTable"

import styles from "./settings.module.scss"
import { useNavigate } from "react-router-dom"

const Index: React.FC = () => {
  const navigate = useNavigate()
  const handleNavigate = () => {
    navigate(-1)
  }
  return (
    <>
      <div className={styles.settings}>
        <div className={styles.settings_header}>
          <div className={styles.settings_header_top}>
            <ArrowLeftIcon onClick={handleNavigate} />
            <span>Sozlamalar</span>
          </div>
          <Tabs
            items={[
              { key: "1", label: "Admin qo'shish", children: <AdminsTable /> },
              { key: "2", label: "Kategoriyalar", children: <CategoryTable /> },
              { key: "3", label: "Tag", children: <TagsTable /> },
            ]}
          />
        </div>
      </div>
    </>
  )
}

export default Index
