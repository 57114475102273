import React from "react"

type Props = {
  color?: string
}

const Wallet3Icon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.5 11.15H7.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 11.15V6.53003C2.5 4.49003 4.15 2.84003 6.19 2.84003H11.81C13.85 2.84003 15.5 4.11002 15.5 6.15002"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.98 12.2C17.48 12.68 17.24 13.42 17.44 14.18C17.69 15.11 18.61 15.7 19.57 15.7H20.5V17.15C20.5 19.36 18.71 21.15 16.5 21.15H6.5C4.29 21.15 2.5 19.36 2.5 17.15V10.15C2.5 7.94002 4.29 6.15002 6.5 6.15002H16.5C18.7 6.15002 20.5 7.95002 20.5 10.15V11.6H19.42C18.86 11.6 18.35 11.82 17.98 12.2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5002 12.62V14.68C22.5002 15.24 22.0402 15.7 21.4702 15.7H19.5402C18.4602 15.7 17.4702 14.91 17.3802 13.83C17.3202 13.2 17.5602 12.61 17.9802 12.2C18.3502 11.82 18.8602 11.6 19.4202 11.6H21.4702C22.0402 11.6 22.5002 12.06 22.5002 12.62Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Wallet3Icon
