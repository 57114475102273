import React, { useState } from "react"
import SearchIcon from "../../../../assets/icons/SearchIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import CategoryDrawer from "../drawers/CategoryDrawer"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import itemRender from "../../../../utils/helpers/ItemReader"
import { Button, Input, Skeleton, Table } from "antd"
import { useInvestmentCategory } from "../../../investments/service/queries"
import { CategoryModels } from "../../../investments/utils/models/categoryModels"
import { useDeleteCategory } from "../../services/mutation"

import styles from "../admins/admins.module.scss"
import { useQueryParam } from "../../../../hooks/useQueryParams"

const CategoryTable: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<CategoryModels | undefined>()
  const categoryDelete = useDeleteCategory()
  const { searchParams, searchParamsString, appendMultipleDifferent } =
    useQueryParam<any, any>()
  const { data, isLoading } = useInvestmentCategory(searchParamsString)
  const [search, setSearch] = useState(searchParams.search)

  const changeSearch = (value: string) => {
    setSearch(value)
    appendMultipleDifferent(["search"], [value])
  }

  //handleOpen
  const handleOpen = () => {
    setOpenDrawer(true)
  }

  //handleUpdate
  const handleUpdate = (render: CategoryModels) => {
    handleOpen()
    setModalData(render)
  }

  //handleDelete
  const handleDelete = (id: number) => {
    return categoryDelete.mutateAsync(id)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Kategoriyalar",
      dataIndex: "name_uz",
      key: "name_uz",
    },
    {
      title: "",
      key: "actions",
      render: (render: CategoryModels) => (
        <div className={styles.actions}>
          <DeleteConfirm
            text={`${render?.name_uz} ni o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(render.id)}
            isLoading={categoryDelete?.isLoading}
          >
            <span className={styles.actions_delete}>O'chirish</span>
          </DeleteConfirm>
          <span
            className={styles.actions_update}
            onClick={() => handleUpdate(render)}
          >
            Tahrirlash
          </span>
        </div>
      ),
      width: 740,
    },
  ]

  return (
    <div className={styles.adminTable}>
      <div className={styles.adminTable_header}>
        <Input
          placeholder="Qidirmoq"
          prefix={<SearchIcon />}
          value={search}
          onChange={(e) => changeSearch(e.target.value)}
        />
        <Button onClick={handleOpen}>
          <PlusIcon />
          <span>Yangi qo'shish</span>
        </Button>
      </div>
      {!isLoading ? (
        <Table
          columns={columns}
          dataSource={data?.data}
          pagination={{
            onChange: (page) => changePage(page, 10),
            current: data?.current_page,
            total: data?.total,
            itemRender: itemRender,
            hideOnSinglePage: true,
          }}
        />
      ) : (
        <Skeleton active />
      )}
      <CategoryDrawer
        modalData={modalData}
        setModalData={setModalData}
        openCategory={openDrawer}
        setOpenCategory={setOpenDrawer}
      />
    </div>
  )
}

export default CategoryTable
