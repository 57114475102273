import React, { useState } from "react"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import { useGetEvents } from "../../services/queries"
import { useDeleteEvent } from "../../services/mutation"
import { Button, Input, Pagination, Skeleton } from "antd"
import SearchIcon from "../../../../assets/icons/SearchIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import dayjs from "dayjs"
import EventDrawers from "../drawers/EventDrawers"
import { EventsModel } from "../../utils/models/eventsModel"
import itemRender from "../../../../utils/helpers/ItemReader"

import styles from "../../innovation.module.scss"
import { useQueryParam } from "../../../../hooks/useQueryParams"

const EventsTable = () => {
  const [modalData, setModalData] = useState<EventsModel | undefined>()
  const [openDrawer, setOpenDrawer] = useState(false)
  const { searchParams, searchParamsString, appendMultipleDifferent } =
    useQueryParam<any, any>()
  const { data, isLoading } = useGetEvents(searchParamsString)
  const deleteEvent = useDeleteEvent()
  const [search, setSearch] = useState(searchParams.search)
  const changeSearch = (value: string) => {
    setSearch(value)
    appendMultipleDifferent(["search"], [value])
  }

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  //handleUpdate
  const handleUpdate = (render: EventsModel) => {
    setModalData(render)
    handleOpenDrawer()
  }

  //handleDeleteYangilik
  const handleDeleteEvent = (slug: string) => {
    return deleteEvent.mutateAsync(slug)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  return (
    <div className={styles.newsTable}>
      {data?.data && (
        <div className={styles.newsTable_header}>
          <Input
            prefix={<SearchIcon />}
            placeholder="Search for trades"
            value={search}
            onChange={(e) => changeSearch(e.target.value)}
          />
          <Button onClick={handleOpenDrawer}>
            <PlusIcon />
            <span>Yangi qo'shish</span>
          </Button>
        </div>
      )}
      {!isLoading ? (
        <div className={styles.innovation}>
          {data?.data?.map((item) => (
            <div className={styles.innovation_child} key={item?.id}>
              <div className={styles.innovation_child_left}>
                {item?.files.length !== 0 ? (
                  <img src={`${process.env.REACT_APP_API_URL}${item.files[0]}`} />
                ) : (
                  <div className={styles.innovation_child_img}></div>
                )}
                <div className={styles.innovation_child_left_text}>
                  <h5>{item?.name_uz}</h5>
                  <p dangerouslySetInnerHTML={{ __html: item?.description_uz }} />
                  <div className={styles.innovation_child_left_text_link}>
                    <a href="#">{item?.location}</a>
                    <span>{dayjs(item?.date).format("MMM DD, YYYY")}</span>
                  </div>
                </div>
              </div>
              <div className={styles.innovation_child_right}>
                <DeleteConfirm
                  text={`${item?.name_uz} ni o'chirmoqchimisiz?`}
                  onConfirm={() => handleDeleteEvent(item?.slug)}
                >
                  <span className={styles.innovation_child_right_delete}>
                    O'chirish
                  </span>
                </DeleteConfirm>
                <span
                  className={styles.innovation_child_right_update}
                  onClick={() => handleUpdate(item)}
                >
                  Tahrirlash
                </span>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Skeleton active />
      )}

      <Pagination
        onChange={(page) => changePage(page, 10)}
        current={data?.current_page}
        total={data?.total}
        itemRender={itemRender}
        hideOnSinglePage
      />
      <EventDrawers
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
        modalData={modalData}
        setModalData={setModalData}
      />
    </div>
  )
}

export default EventsTable
