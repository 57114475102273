import React, { useState } from "react"
import BodyLayout from "../../common/body-layout/BodyLayout"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import CommentDrawer from "./components/comment-drawer/CommentDrawer"
import itemRender from "../../utils/helpers/ItemReader"
import { Skeleton, Table } from "antd"
import { useInvestorComments } from "./services/queries"
import { InvestorCommentModel } from "./utils/models/investorCommentModel"
import { useDeleteComment } from "./services/mutation"

import styles from "./investorComment.module.scss"
import { useQueryParam } from "../../hooks/useQueryParams"

const Index: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<InvestorCommentModel | undefined>()
  const { searchParamsString, appendMultipleDifferent } = useQueryParam()
  const { data: comment, isLoading } = useInvestorComments(searchParamsString)
  const deleteComment = useDeleteComment()

  //handleDeleteComment
  const handleDeleteComment = (id: number) => {
    return deleteComment.mutateAsync(id)
  }

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  //handleUpdate
  const handleUpdate = (render: InvestorCommentModel) => {
    setModalData(render)
    handleOpenDrawer()
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Kompaniya",
      key: "company",
      width: 528,
      render: (render: InvestorCommentModel) => (
        <div className={styles.commentName}>
          <div className={styles.commentName_avatar}>
            {render?.image ? (
              <img
                src={`${process.env.REACT_APP_API_URL}${render?.image}`}
                alt="error"
              />
            ) : (
              render?.investor_name && render.investor_name.substring(0, 1)
            )}
          </div>
          <span>{render.company}</span>
        </div>
      ),
    },
    {
      title: "Investor nomi",
      dataIndex: "investor_name",
      key: "investor_name",
      width: 241,
    },
    {
      title: "Izoh",
      key: "comment",
      render: (render: InvestorCommentModel) => (
        <span>
          {render?.comment && render.comment.substring(0, 100)}
          {render?.comment && render.comment.length > 100 ? "..." : ""}
        </span>
      ),
      width: 359,
    },
    {
      title: "",
      key: "actions",
      render: (render: InvestorCommentModel) => {
        return (
          <div className={styles.investorComment_actions}>
            <DeleteConfirm
              text={`${render.company} invistitsiyasini o'chirmoqchimisiz?`}
              isLoading={deleteComment.isLoading}
              onConfirm={() => handleDeleteComment(render.id)}
            >
              <span className={styles.investorComment_actions_delete}>
                O'chirish
              </span>
            </DeleteConfirm>
            <span
              className={styles.investorComment_actions_edit}
              onClick={() => handleUpdate(render)}
            >
              Tahrirlash
            </span>
          </div>
        )
      },
      width: 359,
    },
  ]

  return (
    <BodyLayout
      title="Investorlar izohlari"
      createButton={{ text: "Yangi yaratish", onCreate: handleOpenDrawer }}
    >
      {!isLoading ? (
        <Table
          className={styles.investorComment}
          columns={columns}
          dataSource={comment?.data}
          pagination={{
            onChange: (page) => changePage(page, 10),
            current: comment?.current_page,
            total: comment?.total,
            itemRender: itemRender,
            hideOnSinglePage: true,
          }}
        />
      ) : (
        <Skeleton active />
      )}
      <CommentDrawer
        setModalData={setModalData}
        modalData={modalData}
        setOpenDrawer={setOpenDrawer}
        openDrawer={openDrawer}
      />
    </BodyLayout>
  )
}

export default Index
