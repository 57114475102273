import React, { useState, useEffect } from "react"
import { Button, Drawer, Form, Tabs, Upload } from "antd"
import { ValidateErrorEntity } from "rc-field-form/lib/interface"
import {
  useCreateAdministration,
  useUpdateAdministration,
} from "../../services/mutation"
import { AdministrationModel } from "../../utils/models/AdministrationModel"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import TabItem from "../tab-item/TabItem"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import FileIcon from "../../../../assets/icons/FileIcon"

import styles from "../../management.module.scss"

const Dragger = Upload

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  openDrawer: boolean
  setModalData: React.Dispatch<React.SetStateAction<AdministrationModel | undefined>>
  modalData: AdministrationModel | undefined
}

const ManagementDrawer: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("uz")

  const createAdministration = useCreateAdministration()
  const updateAdministration = useUpdateAdministration(modalData?.id)

  const handleClose = () => {
    setOpenDrawer(false)
    setModalData(undefined)
    form.resetFields()
  }

  //handle change tabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo.errorFields[0]?.name[0] as string
    const errorTab = name.slice(name.length - 2)

    setActiveTab(errorTab)
  }

  const onFinish = (values: AdministrationModel) => {
    const formData = new FormData()
    let key: keyof typeof values
    for (key in values) {
      if (values[key] && key !== "image") {
        formData.append(key, values[key] as string)
      }
    }
    if (values.image && typeof values.image !== "string") {
      formData.append("image", values.image)
    }
    if (modalData) {
      updateAdministration.mutateAsync(formData).then(handleClose)
    } else {
      createAdministration.mutateAsync(formData).then(handleClose)
    }
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue(modalData)
    }
  }, [modalData])

  return (
    <Drawer
      open={openDrawer}
      title={
        <div className={styles.drawerTitle}>
          <span>Ma'muriyat qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
      onClose={handleClose}
      width={544}
    >
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        className={styles.drawer}
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <TabItem language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <TabItem language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <TabItem language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <TabItem language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <TabItem language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChangeTabs}
        />
        <Form.Item
          label="Rasm"
          name={"image"}
          getValueFromEvent={handleFileFromEvent}
        >
          <Dragger
            className={styles.managementUpload}
            multiple
            iconRender={() => <FileIcon />}
            accept=".png, .svg, .jpg, .jpeg, .gif, .ico"
            customRequest={() => null}
            maxCount={1}
          >
            <UploadIcon />
            <p className="ant-upload-text">
              <span>Click to upload</span> or drag and drop
            </p>
            <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </Dragger>
        </Form.Item>
        {/* {modalData?.image && (
          <div className={styles.uploadFiles}>
            <div className={styles.uploadFiles_child}>
              <div className={styles.uploadFiles_child_left}>
                <FileIcon />
                <span>{modalData.image.substring(14)}</span>
              </div>
              <DeleteIcon />
            </div>
          </div>
        )} */}
        <div className={styles.btns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            htmlType="submit"
            loading={
              createAdministration.isLoading || updateAdministration.isLoading
            }
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default ManagementDrawer
