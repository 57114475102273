import React, { useState, useEffect } from "react"
import { Button, Form, Modal, Tabs } from "antd"
import { ValidateErrorEntity } from "rc-field-form/lib/interface"
import { FaqModel, FaqUpdateModel } from "../../utils/models/FaqModel"
import { useCreateFaq, useUpdateFaq } from "../../services/mutation"
import TabItem from "../tab-item/TabItem"

import styles from "./drawers.module.scss"

type Props = {
  setOpenFaq: React.Dispatch<React.SetStateAction<boolean>>
  openFaq: boolean
  faqId: number | undefined
  modalData: FaqUpdateModel | undefined
  setModalData: React.Dispatch<React.SetStateAction<FaqUpdateModel | undefined>>
}

const FaqModal: React.FC<Props> = ({
  openFaq,
  setOpenFaq,
  faqId,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("uz")
  const createFaq = useCreateFaq()
  const updateFaq = useUpdateFaq(modalData?.id)

  //handleChange
  const handleChange = (isActive: string) => {
    setActiveTab(isActive)
  }

  //onFinishFailed
  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0].name[0] as string
    const errorTab = name.slice(name?.length - 2)
    setActiveTab(errorTab)
  }

  //handleClose
  const handleClose = () => {
    setOpenFaq(false)
    setModalData(undefined)
    form.resetFields()
  }

  const onFinish = (values: FaqModel) => {
    if (modalData) {
      updateFaq.mutateAsync({ ...values, sinf: faqId }).then(handleClose)
    } else {
      createFaq.mutateAsync({ ...values, sinf: faqId }).then(handleClose)
    }
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue(modalData)
    }
  }, [modalData])

  return (
    <Modal
      open={openFaq}
      onCancel={handleClose}
      title="Faq qo'shish"
      footer={false}
      className={styles.faqModal}
      width={1100}
    >
      <Form
        form={form}
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <TabItem language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <TabItem language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <TabItem language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <TabItem language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <TabItem language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChange}
        />
        <div className={styles.faqModal_btns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            htmlType="submit"
            loading={createFaq.isLoading || updateFaq.isLoading}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default FaqModal
