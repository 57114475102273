import React from "react"

type Props = {
  name?: string
}

const PlusIcon1: React.FC<Props> = ({ name }) => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      name={name}
    >
      <path
        d="M8 1V15M1 8H15"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusIcon1
