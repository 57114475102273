import { Form } from "antd"
import React from "react"
import ReactInputMask from "react-input-mask"

import { inputMasks } from "../../utils/constants/inputMasks"
import { lengthValidator } from "../../utils/helpers/lengthValidator"

type Props = {
  name: string
  label: string
  required: boolean
}

export const PhoneInput: React.FC<Props> = ({ name, label, required }) => {
  return (
    <Form.Item
      name={name}
      label={label}
      rules={[{ required: required, message: "" }, lengthValidator(12)]}
      initialValue=""
    >
      <ReactInputMask
        mask={inputMasks.PHONE}
        // @ts-ignore
        maskChar=""
        className="ant-input css-168s3g6 css-dev-only-do-not-override-1xxqrdy input-mask css-1xxqrdy"
      />
    </Form.Item>
  )
}
