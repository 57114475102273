import AirplaneIcon from "../../assets/icons/AirplaneIcon"
import CarIcon from "../../assets/icons/CarIcon"
import CardPosIcon from "../../assets/icons/CardPosIcon"
import ChartSquare from "../../assets/icons/ChartSquare"
import CoinIcon from "../../assets/icons/CoinIcon"
import DollarCircleIcon from "../../assets/icons/DollarCircleIcon"
import GasStationIcon from "../../assets/icons/GasStationIcon"
import MoneyReciveIcon from "../../assets/icons/MoneyReciveIcon"
import MoneySend from "../../assets/icons/MoneySend"
import PercentageIcon from "../../assets/icons/PercentageIcon"
import ReceipIcon from "../../assets/icons/ReceipIcon"
import Tag2Icon from "../../assets/icons/Tag2Icon"
import TicketIcon from "../../assets/icons/TicketIcon"
import Wallet3Icon from "../../assets/icons/Wallet3Icon"

export const tagsIcons: {
  [key: string]: {
    icon: (color?: string) => JSX.Element
  }
} = {
  icon1: {
    icon: (color?: string) => <ReceipIcon color={color} />,
  },
  coin: {
    icon: (color?: string) => <CoinIcon color={color} />,
  },
  percentage: {
    icon: (color?: string) => <PercentageIcon color={color} />,
  },
  wallet1: {
    icon: (color?: string) => <Wallet3Icon color={color} />,
  },
  recive: {
    icon: (color?: string) => <MoneyReciveIcon color={color} />,
  },
  wallet2: {
    icon: (color?: string) => <Wallet3Icon color={color} />,
  },
  dollar: {
    icon: (color?: string) => <DollarCircleIcon color={color} />,
  },
  ticket: {
    icon: (color?: string) => <TicketIcon color={color} />,
  },
  tag2: {
    icon: (color?: string) => <Tag2Icon color={color} />,
  },
  airplane: {
    icon: (color?: string) => <AirplaneIcon color={color} />,
  },
  car: {
    icon: (color?: string) => <CarIcon color={color} />,
  },
  "gas-station": {
    icon: (color?: string) => <GasStationIcon color={color} />,
  },
  "card-pos": {
    icon: (color?: string) => <CardPosIcon color={color} />,
  },
  "chart-square": {
    icon: (color?: string) => <ChartSquare color={color} />,
  },
  "money-send": {
    icon: (color?: string) => <MoneySend color={color} />,
  },
  wallet3: {
    icon: (color?: string) => <Wallet3Icon color={color} />,
  },
}
