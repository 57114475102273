export const rootPaths = {
    AUTH: '/',
    INVESTMENTS: '/investments',
    INVESTOR_COMMENT: '/comment',
    INVESTMENTS_OFFER: '/offer',
    INVESTMENTS_INNOVATIONS: '/innovations',
    SETTINGS: '/settings',
    PROFILE: '/profile',
    ADMINISTRATION: '/administration',
    FAQ: '/faq',
    PRODUCTS: '/products',
    FOSSILS: "/fossils",
    QAZILMALAR: "/qazilmalar",
    SANOAT_TARMOQLARI: "/sanoat-tarmoqlari"
}