import { Button, Drawer, Form, Tabs } from "antd"
import React, { useEffect, useState } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import { ValidateErrorEntity } from "rc-field-form/es/interface"

import styles from "./createDrawer.module.scss"
import EditLanguage from "../languages/EditLanguages"
import { CategoryModels } from "../../../investments/utils/models/categoryModels"
import { useCreateCategory, useUpdateCategory } from "../../services/mutation"

type Props = {
  setOpenCategory: React.Dispatch<React.SetStateAction<boolean>>
  openCategory: boolean
  setModalData: React.Dispatch<React.SetStateAction<CategoryModels | undefined>>
  modalData: CategoryModels | undefined
}

const CategoryDrawer: React.FC<Props> = ({
  openCategory,
  setOpenCategory,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("uz")
  const createCategory = useCreateCategory()
  const updateCategory = useUpdateCategory(modalData?.id)

  //handleCloseCategory
  const handleCloseCategory = () => {
    setOpenCategory(false)
    setModalData(undefined)
    form.resetFields()
  }

  //handleChangeTabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0].name[0] as string
    const errorTab = name?.slice(name.length - 2)
    setActiveTab(errorTab)
  }

  const onFinish = (values: CategoryModels) => {
    if (modalData) {
      updateCategory.mutateAsync(values).then(handleCloseCategory)
    } else {
      createCategory.mutateAsync(values).then(handleCloseCategory)
    }
  }
  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData })
    }
  }, [modalData])

  return (
    <Drawer
      open={openCategory}
      title={
        <div className={styles.drawerTitle}>
          <span>Kategoriya turi</span>
          <CloseIcon onClick={handleCloseCategory} />
        </div>
      }
      onClose={handleCloseCategory}
      width={544}
    >
      <Form
        layout="vertical"
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <EditLanguage label="Nomi" language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <EditLanguage label="Nomi" language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <EditLanguage label="Nomi" language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <EditLanguage label="Nomi" language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <EditLanguage label="Nomi" language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChangeTabs}
          className={styles.tabs}
        />
        <div className={styles.drawer_btns}>
          <Button onClick={handleCloseCategory}>Bekor qilish</Button>
          <Button loading={createCategory.isLoading} htmlType="submit">
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default CategoryDrawer
