import L, { LatLngTuple } from "leaflet"
import { Form, FormInstance } from "antd"
import React, { useEffect, useState } from "react"
import { MapContainer, Marker, TileLayer, useMapEvents } from "react-leaflet"

import styles from "./map.module.scss"
import "leaflet/dist/leaflet.css"

type Props = {
  formInstance: FormInstance
  coords?: number[]
  open: boolean
}

const { Item } = Form

const MapUpdate: React.FC<Props> = ({ formInstance, coords, open }) => {
  const [position, setPosition] = useState(coords)
  const [rendering, setRendering] = useState<boolean>(false)

  useEffect(() => {
    if (coords) {
      setPosition(coords)
    }
  }, [coords])

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        setRendering(true)
      }, 1000)
    } else {
      setRendering(false)
    }
  }, [open])

  const LocationMarker = () => {
    const icon = new L.Icon({
      iconSize: new L.Point(40, 40),
      iconUrl: "/MakerIcon.svg",
    })

    useMapEvents({
      click(e: any) {
        setPosition(Object.values(e?.latlng))
        formInstance.setFieldsValue({
          location: Object.values(e?.latlng),
        })
      },
    })

    return position ? (
      <Marker position={position as LatLngTuple} icon={icon} />
    ) : null
  }

  return rendering ? (
    <Item
      name="location"
      label="Joylashuvi"
      rules={[
        () => ({
          validator() {
            if (!position || position.length === 0) Promise.reject()
            return Promise.resolve()
          },
        }),
        { required: true, message: "" },
      ]}
    >
      <div className={`${styles.map} map`}>
        <span id="location" />
        <MapContainer
          className={styles.map_container}
          center={(position as LatLngTuple) || [40.008544, 65.36646]}
          zoom={10}
          scrollWheelZoom={true}
        >
          <TileLayer
            attribution=""
            url="https://core-renderer-tiles.maps.yandex.net/tiles?l=map&x={x}&y={y}&z={z}&scale=1&lang=ru_RU'"
          />
          <LocationMarker />
        </MapContainer>
      </div>
    </Item>
  ) : null
}

export default MapUpdate
