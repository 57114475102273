import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { PaginationModel } from "../../../utils/models/PaginationModel";
import { AdministrationModel } from "../utils/models/AdministrationModel";

export function useGetAdministration(searchParams: string) {

    return useQuery<PaginationModel<AdministrationModel>>
        ([queryKeys.ADMINISTRATION, searchParams], async () => {
            const res = await $api.get(endpoints.ADMINISTRATION + searchParams)
            return res.data
        })
}