import React, { useEffect, useState } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import PlusIcon1 from "../../assets/icons/PlusIcon1"
import { Button, Drawer, Form, Input, Upload } from "antd"
import { AdminModel } from "../../utils/models/adminModel"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import {
  useCreateSettingsAdmin,
  useUpdateSettingsAdmin,
} from "../../services/mutation"

import styles from "./createDrawer.module.scss"
import { PhoneInput } from "../../../../common/phone-input/PhoneInput"

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setModalData: React.Dispatch<React.SetStateAction<AdminModel | undefined>>
  modalData: AdminModel | undefined
  openDrawer: boolean
}

const AdminCreateDrawer: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  modalData,
  setModalData,
}) => {
  const createSettingsAdmin = useCreateSettingsAdmin()
  const updateSettingsAdmin = useUpdateSettingsAdmin(modalData?.id)
  const [form] = Form.useForm()
  const [image, setImage] = useState<Blob | undefined | string>()

  //handleClose
  const handleCloseDrawer = () => {
    setOpenDrawer(false)
    setModalData(undefined)
    setImage(undefined)
    form.resetFields()
  }

  // change
  const handleChange = (e: UploadChangeParam<UploadFile>) => {
    setImage(e?.file?.originFileObj)
  }

  // image source
  const imageSource = () => {
    if (typeof image === "string") return image as string
    return URL.createObjectURL(image as Blob)
  }

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  const onFinish = (values: AdminModel) => {
    const formData = new FormData()
    let key: keyof typeof values
    for (key in values) {
      if (values[key] && key !== "image") {
        formData.append(key, values[key] as string)
      }
    }
    if (image && typeof image !== "string") {
      formData.append("image", image)
    }
    if (modalData) {
      updateSettingsAdmin.mutateAsync(formData).then(handleCloseDrawer)
    } else {
      createSettingsAdmin.mutateAsync(formData).then(handleCloseDrawer)
    }
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData, password: "" })
    }
  }, [modalData])

  return (
    <Drawer
      open={openDrawer}
      title={
        <div className={styles.drawerTitle}>
          <span>Admin qo'shish</span>
          <CloseIcon onClick={handleCloseDrawer} />
        </div>
      }
      onClose={handleCloseDrawer}
      width={544}
      className={styles.drawer}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
        <div className={styles.drawer_profile}>
          <div className={styles.upload}>
            {image ? (
              <img alt="" src={imageSource()} />
            ) : modalData?.image ? (
              <img src={`${process.env.REACT_APP_API_URL}${modalData?.image}`} />
            ) : (
              <div className={styles.addAdminImg}>
                <PlusIcon1 />
              </div>
            )}
            <Form.Item name={"image"} getValueFromEvent={handleFileFromEvent}>
              <Upload
                name="avatar"
                listType="picture-card"
                className={styles.upload_avatar}
                showUploadList={false}
                customRequest={() => null}
                onChange={handleChange}
                accept="image/*"
              >
                <div className={styles.upload_avatar_in}></div>
              </Upload>
            </Form.Item>
          </div>

          <Form.Item
            label="Ismi"
            name="FIO"
            rules={[{ required: true, message: "" }]}
          >
            <Input />
          </Form.Item>
        </div>
        <PhoneInput name="phone" label="Telefon raqami" required={false} />
        <Form.Item
          label="Login"
          name="username"
          rules={[{ required: true, message: "" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Parol"
          name="password"
          rules={[{ required: true, message: "" }]}
        >
          <Input.Password />
        </Form.Item>
        <div className={styles.drawer_btns}>
          <Button onClick={handleCloseDrawer}>Bekor qilish</Button>
          <Button htmlType="submit">Saqlash</Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default AdminCreateDrawer
