import React from "react"

const ProductIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.16992 7.44043L11.9999 12.5504L20.7699 7.47043"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 21.61V12.54"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.92989 2.48028L4.58989 5.44028C3.37989 6.11028 2.38989 7.79028 2.38989 9.17028V14.8203C2.38989 16.2003 3.37989 17.8803 4.58989 18.5503L9.92989 21.5203C11.0699 22.1503 12.9399 22.1503 14.0799 21.5203L19.4199 18.5503C20.6299 17.8803 21.6199 16.2003 21.6199 14.8203V9.17028C21.6199 7.79028 20.6299 6.11028 19.4199 5.44028L14.0799 2.47028C12.9299 1.84028 11.0699 1.84028 9.92989 2.48028Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ProductIcon
