import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { rootPaths } from "./rootPaths"

const CheckAuth = () => {
  const isAuth = !!localStorage.getItem("token")

  return !isAuth ? <Outlet /> : <Navigate to={rootPaths.INVESTMENTS} />
}

export default CheckAuth
