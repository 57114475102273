import React from "react"
import BodyLayout from "../../common/body-layout/BodyLayout"
import NewsTable from "./components/news/NewsTable"
import EventsTable from "./components/events/EventsTable"

import styles from "./innovation.module.scss"

const Index: React.FC = () => {
  return (
    <div className={styles.news}>
      <BodyLayout
        title="Yangiliklar"
        tabs={[
          { label: "Yangiliklar", key: "1", children: <NewsTable /> },
          { label: "Voqealar", key: "2", children: <EventsTable /> },
        ]}
      />
    </div>
  )
}

export default Index
