import React from "react"

type Props = {
  color?: string
}

const AirplaneIcon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.53023 21.69L11.8602 19.73C12.2102 19.43 12.7902 19.43 13.1402 19.73L15.4702 21.69C16.0102 21.96 16.6702 21.69 16.8702 21.11L17.3102 19.78C17.4202 19.46 17.3102 18.99 17.0702 18.75L14.8002 16.47C14.6302 16.31 14.5002 15.99 14.5002 15.76V12.91C14.5002 12.49 14.8102 12.29 15.2002 12.45L20.1102 14.57C20.8802 14.9 21.5102 14.49 21.5102 13.65V12.36C21.5102 11.69 21.0102 10.92 20.3902 10.66L14.8002 8.25001C14.6402 8.18001 14.5002 7.97001 14.5002 7.79001V4.79001C14.5002 3.85001 13.8102 2.74001 12.9702 2.31001C12.6702 2.16001 12.3202 2.16001 12.0202 2.31001C11.1802 2.74001 10.4902 3.86001 10.4902 4.80001V7.80001C10.4902 7.98001 10.3502 8.19001 10.1902 8.26001L4.61023 10.67C3.99023 10.92 3.49023 11.69 3.49023 12.36V13.65C3.49023 14.49 4.12023 14.9 4.89023 14.57L9.80023 12.45C10.1802 12.28 10.5002 12.49 10.5002 12.91V15.76C10.5002 15.99 10.3702 16.31 10.2102 16.47L7.94023 18.75C7.70023 18.99 7.59023 19.45 7.70023 19.78L8.14023 21.11C8.32023 21.69 8.98023 21.97 9.53023 21.69Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default AirplaneIcon
