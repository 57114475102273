import React from "react"

const InvestmentIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.32 12.0001C20.92 12.0001 22 11.0001 21.04 7.72006C20.39 5.51006 18.49 3.61006 16.28 2.96006C13 2.00006 12 3.08006 12 5.68006V8.56006C12 11.0001 13 12.0001 15 12.0001H18.32Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.0004 14.7C19.0704 19.33 14.6304 22.69 9.58042 21.87C5.79042 21.26 2.74042 18.21 2.12042 14.42C1.31042 9.39001 4.65042 4.95001 9.26042 4.01001"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default InvestmentIcon
