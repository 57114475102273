import React, { useState, useEffect } from "react"
import { Button, Drawer, Form, Input, Select, Tabs, Upload } from "antd"
import { ValidateErrorEntity } from "rc-field-form/es/interface"
import { YangilikCreateModel } from "../../utils/models/newsCreateModel"
import { useCreateYangilik, useUpdateYangilik } from "../../services/mutation"
import { NewsModel } from "../../utils/models/newsModel"
import { useInvestmentTags1 } from "../../../investments/service/queries"
import dayjs from "dayjs"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import EditLanguage from "../languages/EditLanguage"
import DatePicker from "../../../../common/date-picker/DatePicker"

const { Dragger } = Upload
const { Option } = Select

import styles from "./innovationDrawers.module.scss"

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setModalData: React.Dispatch<React.SetStateAction<NewsModel | undefined>>
  modalData: NewsModel | undefined
  openDrawer: boolean
}

const InnovationDrawers: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  modalData,
  setModalData,
}) => {
  const [activeTab, setActiveTab] = useState("uz")
  const [form] = Form.useForm()
  const { data: tags } = useInvestmentTags1()
  const createNews = useCreateYangilik()
  const updateNews = useUpdateYangilik(modalData?.slug)

  const onFinishField = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo.errorFields[0].name[0] as string
    const tabs = ["uz", "ru", "en", "xi", "ar"]
    const errorTab = name.slice(name.length - 2)
    setActiveTab(errorTab)

    if (tabs.includes(errorTab)) {
      setActiveTab(errorTab)
    }

    setTimeout(() => {
      document
        .getElementById(name)
        ?.scrollIntoView({ block: "center", behavior: "smooth" })
    }, 200)
  }

  //onFinish
  const onFinish = (values: YangilikCreateModel) => {
    const formData = new FormData()
    Object.keys(values).forEach((element) => {
      if (
        values[element as keyof typeof values] &&
        element !== "files" &&
        element !== "tag"
      ) {
        formData.append(element, values[element as keyof typeof values] as string)
      }
    })
    values.tag?.forEach((tag) => {
      formData.append("tag", String(tag))
    })
    values.files?.fileList?.forEach((image) => {
      formData.append("files", image.originFileObj)
    })
    if (modalData) {
      modalData?.files?.forEach((image) => {
        formData.append("files", image)
      })
      updateNews.mutateAsync(formData).then(handleClose)
    } else {
      createNews.mutateAsync(formData).then(handleClose)
    }
  }

  //handleChangeTabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  //handleClose
  const handleClose = () => {
    setOpenDrawer(false)
    setModalData(undefined)
    form.resetFields()
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData, date: dayjs(modalData?.date) })
    }
  }, [modalData])

  return (
    <Drawer
      open={openDrawer}
      onClose={handleClose}
      width={1072}
      title={
        <div className={styles.drawersTitle}>
          <span>Yangiliklar qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
      className={styles.innovationDrawer}
    >
      <Form
        layout="vertical"
        form={form}
        onFinishFailed={onFinishField}
        onFinish={onFinish}
        autoComplete="off"
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <EditLanguage language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <EditLanguage language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <EditLanguage language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <EditLanguage language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <EditLanguage language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChangeTabs}
        />
        <div className={styles.formTop}>
          <Form.Item label="Sanasi" name="date">
            <DatePicker />
          </Form.Item>
        </div>
        <Form.Item
          label="Rasm"
          name="files"
          rules={[{ required: true, message: "" }]}
        >
          <Dragger className={styles.innovationUpload}>
            <UploadIcon />
            <p className="ant-upload-text">
              <span>Click to upload</span> or drag and drop
            </p>
            <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </Dragger>
        </Form.Item>
        <div className={styles.innovationSource}>
          <Form.Item label="Tag" name="tag">
            <Select mode="multiple">
              {tags?.data?.map((item) => (
                <Option key={item?.id} value={item?.id}>
                  {item?.name_uz}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Source link"
            name="source"
            rules={[{ required: true, message: "" }]}
          >
            <Input addonBefore="https://" />
          </Form.Item>
        </div>
        <Form.Item
          label="Izoh"
          name="slug"
          rules={[{ required: true, message: "" }]}
        >
          <Input.TextArea placeholder="Izoh yozing" />
        </Form.Item>
        <div className={styles.innovationBtns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            htmlType="submit"
            loading={createNews.isLoading || updateNews.isLoading}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default InnovationDrawers
