import { rootPaths } from "../../routing/rootPaths";
import { LinkModel } from "../../utils/models/LinkModel";
import InvestmentIcon from "../../assets/icons/InvestmentIcon";
import InvestorCommentIcon from "../../assets/icons/InvestorCommentIcon";
import OfferIcon from "../../assets/icons/OfferIcon";
import InnovationsIcon from "../../assets/icons/InnovationsIcon";
import SettingsIcon from "../../assets/icons/SettingsIcon";
import AdministrationIcon from "../../assets/icons/AdministrationtIcon";
import HelpIcon from "../../assets/icons/HelpIcon";
import ProductIcon from "../../assets/icons/ProductIcon";
import FossilsIcon from "../../assets/icons/FossilsIcon";

export const rootLinks: LinkModel[] = [
    {
        path: rootPaths.INVESTMENTS,
        text: "Investitsiyalar",
        icon: InvestmentIcon
    },
    {
        path: rootPaths.INVESTOR_COMMENT,
        text: "Investorlar izohlari",
        icon: InvestorCommentIcon
    },
    {
        path: rootPaths.INVESTMENTS_OFFER,
        text: "Takliflar",
        icon: OfferIcon
    },
    {
        path: rootPaths.PRODUCTS,
        text: "Mahsulotlar",
        icon: ProductIcon
    },
    {
        path: rootPaths.INVESTMENTS_INNOVATIONS,
        text: "Yangiliklar",
        icon: InnovationsIcon
    },
    {
        path: rootPaths.FOSSILS,
        text: "Qazilmalar",
        icon: FossilsIcon
    },
    {
        path: rootPaths.SANOAT_TARMOQLARI,
        text: "Sanoat tarmoqlari",
        icon: ProductIcon
    },
    {
        path: rootPaths.ADMINISTRATION,
        text: "Ma'muriyat",
        icon: AdministrationIcon
    },
    {
        path: rootPaths.FAQ,
        text: "FAQ",
        icon: HelpIcon
    },
    {
        path: rootPaths.SETTINGS,
        text: "Sozlamalar",
        icon: SettingsIcon
    }
]