import { Form, Input } from "antd"
import React from "react"

type Props = {
  language: string
}

const TabItem: React.FC<Props> = ({ language }) => {
  return (
    <>
      <Form.Item
        label="Savol"
        name={`savol_${language}`}
        rules={[{ required: true, message: "" }]}
      >
        <Input.TextArea placeholder="Savol yozing..." />
      </Form.Item>
      <Form.Item
        label="Javob"
        name={`javob_${language}`}
        rules={[{ required: true, message: "" }]}
      >
        <Input.TextArea placeholder="Javob yozing..." />
      </Form.Item>
    </>
  )
}

export default TabItem
