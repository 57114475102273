import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { message } from "antd";

export function useCreateAdministration() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
    const res = await $api.post(endpoints.ADMINISTRATION, req)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.ADMINISTRATION])
      message.success("Muvafaqqiyatli")
    },
    onError: () => {
      message.error("Xatolik")
    }
  })
}

export function useUpdateAdministration(id: number | undefined) {
  const qc = useQueryClient()
  return useMutation<ErrorRes, SuccessRes, FormData>
    (async (req) => {
      const res = await $api.put(endpoints.ADMINISTRATION + id + "/", req)
      return res.data
    }, {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.ADMINISTRATION])
        message.success("Muvafaqqiyatli")
      },
      onError: () => {
        message.error("Xatolik")
      }
    })
}

export function useDeleteAdministration() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(endpoints.ADMINISTRATION + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.ADMINISTRATION])
      message.success("Muvafaqqiyatli")
    },
    onError: () => {
      message.error("Xatolik")
    }
  })
}