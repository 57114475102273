import { PaginationProps } from "antd"
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon"
import ArrowRightIcon from "../../assets/icons/ArrowRightIcon"

const itemRender: PaginationProps["itemRender"] = (_, type, originalElement) => {
  if (type === "prev") {
    return (
      <div>
        <ArrowLeftIcon />
        <span>Oldingi</span>
      </div>
    )
  }
  if (type === "next") {
    return (
      <div>
        <span>Keyingisi</span>
        <ArrowRightIcon />
      </div>
    )
  }
  return originalElement
}

export default itemRender
