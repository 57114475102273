import React, { useState } from "react"
import { Collapse, Skeleton } from "antd"
import { CaretRightOutlined } from "@ant-design/icons"
import { useDeleteFaq, useDeleteFaqClass } from "./services/mutation"
import { useGetFaqClass } from "./services/queries"
import { FaqUpdateModel } from "./utils/models/FaqModel"
import { FaqClassUpdateModal } from "./utils/models/FaqClassModel"
import BodyLayout from "../../common/body-layout/BodyLayout"
import FAQIcon from "../../assets/icons/FAQIcon"
import PlusIcon from "../../assets/icons/PlusIcon"
import FAQClassDrawer from "./components/drawers/FAQClassDrawer"
import FaqModal from "./components/drawers/FaqModal"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import DotsIcon from "../../assets/icons/DotsIcon"
import EditIcon from "../../assets/icons/EditIcon"
import DeleteIcon from "../../assets/icons/DeleteIcon"
import PopoverActions from "../../common/popover-actions/PopoverActions"

import styles from "./help.module.scss"
import { useQueryParam } from "../../hooks/useQueryParams"

const { Panel } = Collapse

const Index: React.FC = () => {
  const [openFaqClass, setOpenFaqClass] = useState(false)
  const [faqId, setFaqId] = useState<number | undefined>()
  const [faqClassId, setFaqClassId] = useState<number>(0)
  const [faqClassName, setFaqClassName] = useState<string>("")
  const [openFaq, setOpenFaq] = useState(false)
  const [modalData, setModalData] = useState<FaqUpdateModel | undefined>()
  const [faqClassData, setFaqClassData] = useState<FaqClassUpdateModal | undefined>()
  const { searchParamsString } = useQueryParam()
  const { data: faqClass, isLoading } = useGetFaqClass(searchParamsString)
  const deleteFaq = useDeleteFaq()
  const deleteFaqClass = useDeleteFaqClass()

  //handleDeleteFaq
  const handleDeleteFaq = (id: number) => {
    return deleteFaq.mutateAsync(id)
  }

  //handleDeleteFaqClass
  const handleDeleteFaqClass = (id: number) => {
    return deleteFaqClass.mutateAsync(id)
  }

  //handleOpen create faq class
  const handleOpenFaqClass = () => {
    setOpenFaqClass(true)
  }

  //handleOpenFaq
  const handleOpenFaq = () => {
    setOpenFaq(true)
  }

  //handleUpdateFaq
  const handleUpdateFaq = (item: FaqUpdateModel) => {
    setModalData(item)
    handleOpenFaq()
  }

  //handleUpdateFaqClass
  const handleUpdateFaqClass = (item: FaqClassUpdateModal) => {
    setFaqClassData(item)
    handleOpenFaqClass()
  }

  return (
    <BodyLayout title="FAQ">
      {!isLoading ? (
        <div className={styles.faqCollapse}>
          <Collapse
            defaultActiveKey={["1"]}
            expandIcon={({ isActive }) => (
              <CaretRightOutlined rotate={isActive ? 90 : 0} />
            )}
          >
            {faqClass?.data?.map((item) => (
              <Panel
                header={
                  <div className={styles.panelHeader}>
                    <div className={styles.panelHeader_left}>
                      <FAQIcon />
                      <span>{item.name_uz}</span>
                    </div>
                    <div
                      className={styles.panelHeader_right}
                      onClick={(e) => e.stopPropagation()}
                    >
                      <span>{item.faq.length} Faq guruh</span>
                      <PopoverActions
                        content={
                          <div className={styles.content}>
                            <div
                              className={styles.content_edit}
                              onClick={() => handleUpdateFaqClass(item)}
                            >
                              <EditIcon />
                              <span>Tahrirlash</span>
                            </div>
                            <DeleteConfirm
                              text={`${faqClassName} sinfni o'chirmoqchimisiz?`}
                              onConfirm={() => handleDeleteFaqClass(faqClassId)}
                              isLoading={deleteFaqClass.isLoading}
                            >
                              <div className={styles.content_delete}>
                                <DeleteIcon />
                                <span>O'chirish</span>
                              </div>
                            </DeleteConfirm>
                          </div>
                        }
                      >
                        <DotsIcon
                          onClick={() => {
                            setFaqClassId(item?.id)
                            setFaqClassName(item?.name_ru)
                            setFaqClassData(item)
                          }}
                        />
                      </PopoverActions>
                    </div>
                  </div>
                }
                key={item?.id}
                className={styles.collapsePanel}
              >
                {item?.faq?.map((data) => (
                  <div className={styles.collapsePanel_body} key={data?.id}>
                    <p>{data?.savol_uz}</p>
                    <div className={styles.collapsePanel_body_actions}>
                      <DeleteConfirm
                        text={`${data?.savol_uz} ni o'chirmoqchimisiz?`}
                        onConfirm={() => handleDeleteFaq(data?.id)}
                        isLoading={deleteFaq?.isLoading}
                      >
                        <span className={styles.collapsePanel_body_actions_delete}>
                          O'chirish
                        </span>
                      </DeleteConfirm>
                      <span
                        className={styles.collapsePanel_body_actions_update}
                        onClick={() => {
                          handleUpdateFaq(data)
                          setFaqId(item?.id)
                        }}
                      >
                        Tahrirlash
                      </span>
                    </div>
                  </div>
                ))}

                <div
                  className={styles.collapsePanel_create}
                  onClick={() => {
                    handleOpenFaq()
                    setFaqId(item.id)
                  }}
                >
                  <PlusIcon />
                  <span>Faq qo'shish</span>
                </div>
              </Panel>
            ))}
          </Collapse>
          <div className={styles.faqCollapse_create} onClick={handleOpenFaqClass}>
            <PlusIcon />
            <span>Faq sinfi qo'shish</span>
          </div>
        </div>
      ) : (
        <Skeleton active />
      )}
      <FAQClassDrawer
        openFaqClass={openFaqClass}
        setOpenFaqClass={setOpenFaqClass}
        faqClassData={faqClassData}
        setFaqClassData={setFaqClassData}
      />
      <FaqModal
        openFaq={openFaq}
        setOpenFaq={setOpenFaq}
        faqId={faqId}
        modalData={modalData}
        setModalData={setModalData}
      />
    </BodyLayout>
  )
}

export default Index
