import React from "react"
import { Form, Input, Button } from "antd"

import bg from "./assets/images/backgroundImg.png"
import AlertCircleIcon from "../../assets/icons/AlertCircleIcon"
import { useLogin } from "./services/mutation"
import { LoginBodyModel } from "./utils/constants/models/loginModel"

import styles from "./auth.module.scss"

const Index: React.FC = () => {
  const [form] = Form.useForm()
  const login = useLogin()

  //onFinish

  const onFinish = (values: LoginBodyModel) => {
    login.mutateAsync(values)
  }

  return (
    <div className={styles.auth}>
      <div className={styles.auth_left}>
        <div className={styles.auth_left_login}>
          <div className={styles.auth_left_login_top}>
            <p>Tizimga kirish</p>
            <span>
              Qaytib kelganingizdan xursandmiz! <br /> Tafsilotlaringizni kiriting.
            </span>
          </div>
          <Form layout="vertical" onFinish={onFinish} form={form} autoComplete="off">
            <Form.Item
              label="Login"
              name="username"
              rules={[{ required: true, message: "" }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Parol"
              name={"password"}
              rules={[
                {
                  required: true,
                  message: (
                    <div className={styles.error}>
                      <AlertCircleIcon />
                      <span>Login yoki parol noto'g'ri kiritilgan!</span>
                    </div>
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Button htmlType="submit" loading={login.isLoading}>
              Login
            </Button>
          </Form>
        </div>
        <p className={styles.auth_left_company}>© Softex 2023</p>
      </div>
      <div className={styles.auth_right}>
        <img src={bg} alt="Error" />
      </div>
    </div>
  )
}

export default Index
