import React, { useState } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import EditIcon from "../../../../assets/icons/EditIcon"
import { Button, Collapse, Drawer, DrawerProps } from "antd"
import { useDeleteInvestSub } from "../../service/mutation"
import { SubInvestModels } from "../../utils/models/subInvestModel"

import styles from "../../investments.module.scss"
import dayjs from "dayjs"

const { Panel } = Collapse

type Props = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  open: boolean
  openAddDrawer: () => void
  balance: number
  subData: SubInvestModels[] | undefined
  setSubModalData: React.Dispatch<React.SetStateAction<SubInvestModels | undefined>>
}

const InvestDrawer: React.FC<Props> = ({
  open,
  setOpen,
  openAddDrawer,
  balance,
  subData,
  setSubModalData,
}) => {
  const [placement] = useState<DrawerProps["placement"]>("right")
  const deleteSubInvest = useDeleteInvestSub()

  //handleClose
  const onClose = () => {
    setOpen(false)
  }

  //handleOpenAddDrawer
  const handleOpenAddDrawer = () => {
    onClose()
    openAddDrawer()
  }

  const handleUpdateSubInvestments = (render: SubInvestModels) => {
    setSubModalData(render)
    handleOpenAddDrawer()
  }

  //handleDeleteSubInvest
  const handleDeleteSubInvest = (id: number) => {
    return deleteSubInvest.mutateAsync(id).then(onClose)
  }

  return (
    <Drawer
      className={styles.drawer}
      title={
        <div className={styles.drowerTitle}>
          <CloseIcon onClick={onClose} />
        </div>
      }
      placement={placement}
      closable={false}
      onClose={onClose}
      open={open}
      key={placement}
      width={544}
    >
      <div className={styles.drower_head}>
        <span>Umumiy balans</span>
        <div className={styles.drower_head_body}>
          <p>{balance.toLocaleString("ru")} so'm</p>
          <Button onClick={handleOpenAddDrawer}>
            <PlusIcon /> Yangi yaratish
          </Button>
        </div>
      </div>
      <div className={styles.drower_table}>
        {subData?.length !== 0 ? (
          <div className={styles.drower_table_head}>
            <span>Tashkilot nomi</span>
            <span>Kiritilgan summa</span>
          </div>
        ) : (
          <div style={{ padding: "30px" }}>Empty</div>
        )}
        {subData?.map((item) => (
          <Collapse key={item?.id} className={styles.collapse} accordion>
            <Panel
              header={
                <div className={styles.drower_table_body_child}>
                  <span>{item.company}</span>
                  <span>{Number(item.summa).toLocaleString("ru")} so'm</span>
                </div>
              }
              key={item.id}
            >
              <div className={styles.drower_table_body_child}>
                <span>Summa</span>
                <span>{Number(item.summa).toLocaleString("ru")} so'm</span>
              </div>
              <div className={styles.drower_table_body_child}>
                <span>Kompaniya</span>
                <span>{item.company}</span>
              </div>
              <div className={styles.drower_table_body_child}>
                <span>Kiritilgan sana</span>
                <span>{dayjs(item.date).format("MMM DD, YYYY")}</span>
              </div>
              <div className={styles.drower_table_body_child}>
                <DeleteConfirm
                  text={`${item?.company} ni o'chirmoqchimisiz?`}
                  onConfirm={() => handleDeleteSubInvest(item.id)}
                  isLoading={deleteSubInvest.isLoading}
                >
                  <span>
                    <DeleteIcon />
                    <p className={styles.drower_table_body_child_delete}>
                      O'chirish
                    </p>
                  </span>
                </DeleteConfirm>
                <span onClick={() => handleUpdateSubInvestments(item)}>
                  <EditIcon />
                  <p className={styles.drower_table_body_child_edit}>Tahrirlash</p>
                </span>
              </div>
            </Panel>
          </Collapse>
        ))}
      </div>
    </Drawer>
  )
}

export default InvestDrawer
