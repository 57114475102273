import { RouteModel } from "../utils/models/RouteModel";
import { rootPaths } from "./rootPaths";
import Investments from "../features/investments/Index";
import InvestorComment from "../features/investor-comment/Index";
import Offer from "../features/offer/Index";
import Innovations from "../features/innovation/Index";
import Settings from "../features/settings/Index";
import Profile from "../features/profile/Index";
import Administration from "../features/management/Index";
import FAQ from "../features/help/Index";
import Products from "../features/products/Index";
import Fossils from "../features/fossils/Index";
import Industry from "../features/industry/Index";


export const rootRoutes: RouteModel[] = [

  {
    path: rootPaths.INVESTMENTS,
    element: Investments,
    isExact: false
  },
  {
    path: rootPaths.INVESTOR_COMMENT,
    element: InvestorComment,
    isExact: true
  },
  {
    path: rootPaths.INVESTMENTS_OFFER,
    element: Offer,
    isExact: true
  },
  {
    path: rootPaths.PRODUCTS,
    element: Products,
    isExact: true
  },
  {
    path: rootPaths.INVESTMENTS_INNOVATIONS,
    element: Innovations,
    isExact: true
  },
  {
    path: rootPaths.SETTINGS,
    element: Settings,
    isExact: true
  },
  {
    path: rootPaths.PROFILE,
    element: Profile,
    isExact: true
  },
  {
    path: rootPaths.ADMINISTRATION,
    element: Administration,
    isExact: true
  },
  {
    path: rootPaths.FAQ,
    element: FAQ,
    isExact: true
  },
  {
    path: rootPaths.FOSSILS,
    element: Fossils,
    isExact: true
  },
  {
    path: rootPaths.QAZILMALAR,
    element: Fossils,
    isExact: true
  },
  {
    path: rootPaths.SANOAT_TARMOQLARI,
    element: Industry,
    isExact: true
  },

]