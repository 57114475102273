import React from "react"

type Props = {
  color?: string
}

const Tag2Icon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.90476 15.5264L9.43476 20.0564C11.2948 21.9164 14.3148 21.9164 16.1848 20.0564L20.5748 15.6664C22.4348 13.8064 22.4348 10.7864 20.5748 8.91637L16.0348 4.39637C15.0848 3.44637 13.7748 2.93637 12.4348 3.00637L7.43476 3.24637C5.43476 3.33637 3.84476 4.92637 3.74476 6.91637L3.50476 11.9164C3.44476 13.2664 3.95476 14.5764 4.90476 15.5264Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.2344 12.2264C11.6151 12.2264 12.7344 11.1071 12.7344 9.72638C12.7344 8.34567 11.6151 7.22638 10.2344 7.22638C8.85366 7.22638 7.73438 8.34567 7.73438 9.72638C7.73438 11.1071 8.85366 12.2264 10.2344 12.2264Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M13.7344 17.2264L17.7344 13.2264"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default Tag2Icon
