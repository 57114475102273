import React, { useState } from "react"
import { useGetIndustry } from "./service/queries"
import { useDeleteIndustry } from "./service/mutation"
import { IndustryModel } from "./utils/models/IndustryModel"
import { useQueryParam } from "../../hooks/useQueryParams"
import { Pagination } from "antd"
import BodyLayout from "../../common/body-layout/BodyLayout"
import itemRender from "../../utils/helpers/ItemReader"
import CreateIndustryModal from "./components/create-industry/Index"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"

import styles from "./index.module.scss"

const Index = () => {
  const { appendMultipleDifferent, searchParamsString } = useQueryParam()
  const { data } = useGetIndustry(searchParamsString)

  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<IndustryModel | undefined>(undefined)
  const deleteIndustry = useDeleteIndustry()
  const handleDeleteIndustry = (id: number) => {
    return deleteIndustry.mutateAsync(id)
  }

  //handle open drawer
  const handleOpen = () => {
    setOpenDrawer(true)
  }

  const handleUpdate = (data: IndustryModel) => {
    handleOpen()
    setModalData(data)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  return (
    <BodyLayout
      title="Sanoat tarmoqlari"
      createButton={{
        onCreate: () => handleOpen(),
        text: "Yangi qo'shish",
      }}
    >
      <div className={styles.container}>
        {data?.data?.map((item) => (
          <div className={styles.item}>
            <div className={styles.item_left}>
              <img src={`${process.env.REACT_APP_API_URL}${item?.image}`} alt="" />
              <div className={styles.item_left_text}>
                <p>{item?.name_uz}</p>
                <span>{item?.text_uz}</span>
              </div>
            </div>
            <div className={styles.item_right}>
              <DeleteConfirm
                text={`${item?.name_uz} ni haqiqatdan ham o'chirmoqchimisiz?`}
                onConfirm={() => handleDeleteIndustry(item?.id)}
                isLoading={deleteIndustry?.isLoading}
              >
                <span className={styles.delete}>O'chirish</span>
              </DeleteConfirm>
              <span onClick={() => handleUpdate(item)}>Tahrirlash</span>
            </div>
          </div>
        ))}
      </div>
      <Pagination
        onChange={(page) => changePage(page, 4)}
        current={data?.current_page}
        total={data?.total}
        itemRender={itemRender}
        pageSize={4}
        hideOnSinglePage
      />
      <CreateIndustryModal
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        modalData={modalData}
        setModalData={setModalData}
      />
    </BodyLayout>
  )
}

export default Index
