import React from "react"

const TrashIcon = () => {
  return (
    <svg
      width="80"
      height="80"
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="40" fill="#FFEBEB" />
      <path
        d="M55.75 29.4651C49.9225 28.8876 44.06 28.5901 38.215 28.5901C34.75 28.5901 31.285 28.7651 27.82 29.1151L24.25 29.4651"
        stroke="#E53535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.875 27.6975L34.26 25.405C34.54 23.7425 34.75 22.5 37.7075 22.5H42.2925C45.25 22.5 45.4775 23.8125 45.74 25.4225L46.125 27.6975"
        stroke="#E53535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M51.9867 34.9951L50.8492 52.6176C50.6567 55.3651 50.4992 57.5001 45.6167 57.5001H34.3817C29.4992 57.5001 29.3417 55.3651 29.1492 52.6176L28.0117 34.9951"
        stroke="#E53535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.0781 47.875H42.9056"
        stroke="#E53535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M35.625 40.875H44.375"
        stroke="#E53535"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default TrashIcon
