import React, { useState, useEffect } from "react"
import { Button, Drawer, Form, Tabs } from "antd"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import { ValidateErrorEntity } from "rc-field-form/es/interface"
import EditLanguage from "../languages/EditLanguages"
import { PhotoshopPicker } from "react-color"
import { TagsModel } from "../../../investments/utils/models/tagsModel"
import { useCreateTag, useUpdateTag } from "../../services/mutation"
import { tagsIcons } from "../tags/TagIcons"

import styles from "./drawers.module.scss"

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  openDrawer: boolean
  modalData: TagsModel | undefined
  setModalData: React.Dispatch<React.SetStateAction<TagsModel | undefined>>
}

const TagsDrawer: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("uz")
  const [currentColor, setCurrentColor] = useState("#5328de")
  const [icon, setIcon] = useState("icon1")
  const createTag = useCreateTag()
  const updateTag = useUpdateTag(modalData?.id)

  //handleChange
  const handleChange = (color: { hex: string }) => {
    setCurrentColor(color.hex)
  }
  //handleClose
  const handleClose = () => {
    setOpenDrawer(false)
    setModalData(undefined)
    form.resetFields()
  }

  //onFinishFailed
  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0]?.name[0] as string
    const errorTab = name.slice(name.length - 2)
    setActiveTab(errorTab)
  }

  //onFinish
  const onFinish = (values: TagsModel) => {
    if (modalData) {
      updateTag
        .mutateAsync({ ...values, color: currentColor, icon })
        .then(handleClose)
    } else {
      createTag
        .mutateAsync({ ...values, color: currentColor, icon })
        .then(handleClose)
    }

    form.resetFields()
  }

  //handleChangeIcon
  const handleChangeIcon = (value: string) => {
    setIcon(value)
  }

  //handleChangeTabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData })
      setIcon(modalData?.icon)
      setCurrentColor(modalData?.color)
    }
  }, [modalData])

  return (
    <>
      <Drawer
        title={
          <div className={styles.drawersTitle}>
            <span>Tag qo'shish</span>
            <CloseIcon onClick={handleClose} />
          </div>
        }
        className={styles.drawer}
        open={openDrawer}
        onClose={handleClose}
        width={560}
      >
        <Form
          layout="vertical"
          form={form}
          onFinishFailed={onFinishFailed}
          onFinish={onFinish}
          autoComplete="off"
        >
          <div className={styles.tagDrawerTop}>
            <Form.Item>
              <div className={styles.plusIcon}>
                {tagsIcons[icon].icon(currentColor)}
              </div>
            </Form.Item>
            <Tabs
              activeKey={activeTab}
              items={[
                {
                  key: "uz",
                  label: "O'zbek",
                  forceRender: true,
                  children: <EditLanguage label="Tag nomi" language="uz" />,
                },
                {
                  key: "ru",
                  label: "Rus",
                  forceRender: true,
                  children: <EditLanguage label="Tag nomi" language="ru" />,
                },
                {
                  key: "en",
                  label: "Ingliz",
                  forceRender: true,
                  children: <EditLanguage label="Tag nomi" language="en" />,
                },
                {
                  key: "xi",
                  label: "Xitoy",
                  forceRender: true,
                  children: <EditLanguage label="Tag nomi" language="xi" />,
                },
                {
                  key: "ar",
                  label: "Arab",
                  forceRender: true,
                  children: <EditLanguage label="Tag nomi" language="ar" />,
                },
              ]}
              onChange={handleChangeTabs}
            />
          </div>
          <div className={styles.icons}>
            {Object.keys(tagsIcons)?.map((item) => (
              <span
                key={item}
                onClick={() => {
                  handleChangeIcon(item)
                }}
              >
                {tagsIcons[item].icon()}
              </span>
            ))}
          </div>
          <Form.Item name="color">
            <div className={styles.colorEditor}>
              <PhotoshopPicker
                color={currentColor}
                onChange={handleChange}
                className={styles.photoshop}
              />
              <div className={styles.colorEditor_current}>
                <div
                  style={{ backgroundColor: `${currentColor}` }}
                  className={styles.colorEditor_current_color}
                ></div>
                <span>{currentColor}</span>
              </div>
            </div>
          </Form.Item>
          <div className={styles.drawersBtns}>
            <Button onClick={handleClose}>Bekor qilish</Button>
            <Button htmlType="submit" loading={createTag.isLoading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </Drawer>
      <style>
        {`
          .colorEditor{
            display: none !important;
          }
        `}
      </style>
    </>
  )
}

export default TagsDrawer
