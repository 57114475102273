import React, { useEffect } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import DatePicker from "../../../../common/date-picker/DatePicker"
import dayjs from "dayjs"
import { Button, Drawer, Form, Input } from "antd"
import { SubInvestModels } from "../../utils/models/subInvestModel"
import {
  useCreateSubInvestments,
  useUpdateSubInvestments,
} from "../../service/mutation"
// import { localeFormatter } from "../../../../hooks/localeFormatter"

import styles from "./drawers.module.scss"

type Props = {
  setAddOpen: React.Dispatch<React.SetStateAction<boolean>>
  addOpen: boolean
  investId: number | undefined
  subModalData: SubInvestModels | undefined
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSubModalData: React.Dispatch<React.SetStateAction<SubInvestModels | undefined>>
}

const AddInvestDrawer: React.FC<Props> = ({
  addOpen,
  setAddOpen,
  investId,
  subModalData,
  setSubModalData,
}) => {
  const [form] = Form.useForm()
  const createSubInvestment = useCreateSubInvestments()
  const updateSubInvestment = useUpdateSubInvestments(subModalData?.id)

  //handleClose
  const handleClose = () => {
    setAddOpen(false)
    setSubModalData(undefined)
  }

  //onFinish
  const onFinish = (values: SubInvestModels) => {
    if (subModalData) {
      updateSubInvestment
        .mutateAsync({ ...values, invest: investId })
        .then(handleClose)
    } else {
      createSubInvestment
        .mutateAsync({ ...values, invest: investId })
        .then(handleClose)
    }
  }

  useEffect(() => {
    if (subModalData) {
      form.setFieldsValue({ ...subModalData, date: dayjs(subModalData?.date) })
    }
  }, [subModalData])

  // const handlePriceFields = (field: string) => {
  //   const value = form.getFieldValue([field])

  //   form.setFieldsValue({
  //     [field]: localeFormatter(value),
  //   })
  // }

  return (
    <Drawer
      open={addOpen}
      title={
        <div className={styles.incomeTitle}>
          <span>Investitsiya qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
      width={544}
      className={styles.incomeDrawer}
      onClose={handleClose}
    >
      <Form layout="vertical" form={form} onFinish={onFinish} autoComplete="off">
        <Form.Item label="Nomi" name="name">
          <Input />
        </Form.Item>
        <Form.Item label="Investor ismi" name="invest">
          <Input />
        </Form.Item>
        <Form.Item label="Kompaniya" name="company">
          <Input />
        </Form.Item>
        <Form.Item label="Summa" name="summa">
          <Input />
        </Form.Item>
        <Form.Item label="Kiritilgan sana" name="date">
          <DatePicker format="MMM DD, YYYY" />
        </Form.Item>
        <div className={styles.incomeDrawer_btns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            htmlType="submit"
            loading={createSubInvestment.isLoading || updateSubInvestment.isLoading}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default AddInvestDrawer
