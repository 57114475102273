import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { message } from "antd";

export function useCreateYangilik() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
        const res = await $api.post(endpoints.YANGILIK, req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.YANGILIK])
            message.success("Muvafaqqiyatli")
        },
        onError: () => {
            message.error("Xatolik")
        }
    })
}

export function useUpdateYangilik(slug: string | undefined) {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>
        (async (req) => {
            const res = await $api.put(endpoints.YANGILIK + slug + "/", req)
            return res.data
        }, {
            onSuccess: () => {
                qc.invalidateQueries([queryKeys.YANGILIK])
                message.success("Muvafaqqiyatli")
            },
            onError: () => {
                message.error("Xatolik")
            }
        })
}

export function useDeleteYangilik() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, string>(async (id) => {
        const res = await $api.delete(endpoints.YANGILIK + id + "/")
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.YANGILIK])
            message.success("Muvafaqqiyatli")
        },
        onError: () => {
            message.error("Xatolik")
        }
    })
}


export function useCreateEvents() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
        const res = await $api.post(endpoints.EVENTS, req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.EVENTS])
            message.success("Muvafaqqiyatli")
        },
        onError: () => {
            message.error("Xatolik")
        }
    })
}

export function useUpdateEvents(slug: string | undefined) {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>
        (async (req) => {
            const res = await $api.put(endpoints.EVENTS + slug + "/", req)
            return res.data
        }, {
            onSuccess: () => {
                qc.invalidateQueries([queryKeys.EVENTS])
                message.success("Muvafaqqiyatli")
            },
            onError: () => {
                message.error("Xatolik")
            }
        })
}

export function useDeleteEvent() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, string>
        (async (slug) => {
            const res = await $api.delete(endpoints.EVENTS + slug + "/")
            return res.data
        }, {
            onSuccess: () => {
                qc.invalidateQueries([queryKeys.EVENTS])
                message.success("Muvafaqqiyatli")
            },
            onError: () => {
                message.error("Xatolik")
            }
        })
}