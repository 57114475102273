import React from "react"

type Props = {
  color?: string
}

const CarIcon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.01 2.83002H8.99C6.5 2.83002 5.95 4.07002 5.63 5.59002L4.5 11H20.5L19.37 5.59002C19.05 4.07002 18.5 2.83002 16.01 2.83002Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.4897 19.82C22.5997 20.99 21.6597 22 20.4597 22H18.5797C17.4997 22 17.3497 21.54 17.1597 20.97L16.9597 20.37C16.6797 19.55 16.4997 19 15.0597 19H9.93974C8.49974 19 8.28974 19.62 8.03974 20.37L7.83974 20.97C7.64974 21.54 7.49974 22 6.41974 22H4.53974C3.33974 22 2.39974 20.99 2.50974 19.82L3.06974 13.73C3.20974 12.23 3.49974 11 6.11974 11H18.8797C21.4997 11 21.7897 12.23 21.9297 13.73L22.4897 19.82Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 8H3.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.5 8H20.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.5 3V5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11 5H14"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.5 15H9.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 15H18.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CarIcon
