import React from "react"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"

const modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "link", "image"],
    [{ list: "ordered" }, { list: "bullet" }],
  ],
}

type Props = {
  value: string
  onChange: (e: any) => void
}

const TextEditor: React.FC<Props> = ({ onChange, value }) => {
  return (
    <ReactQuill onChange={onChange} value={value} theme="snow" modules={modules} />
  )
}

export default TextEditor
