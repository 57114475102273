import React from "react"

type Props = {
  color?: string
}

const ChartSquare: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6103 11.15H7.9603C7.3303 11.15 6.82031 11.66 6.82031 12.29V17.41H10.6103V11.15V11.15Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.2616 6.59998H11.7415C11.1115 6.59998 10.6016 7.10999 10.6016 7.73999V17.4H14.3916V7.73999C14.3916 7.10999 13.8916 6.59998 13.2616 6.59998Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.0484 12.85H14.3984V17.4H18.1884V13.99C18.1784 13.36 17.6684 12.85 17.0484 12.85Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 22H15.5C20.5 22 22.5 20 22.5 15V9C22.5 4 20.5 2 15.5 2H9.5C4.5 2 2.5 4 2.5 9V15C2.5 20 4.5 22 9.5 22Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ChartSquare
