import { Form, Input } from "antd"
import React, { useState } from "react"
import TextEditor from "../../../../common/text-editor/TextEditor"

import styles from "../drawers/innovationDrawers.module.scss"

type Props = {
  language: string
}

const EditLanguage: React.FC<Props> = ({ language }) => {
  const [value, setValue] = useState("")
  return (
    <div className={styles.editing}>
      <Form.Item
        label="Ismi"
        name={`name_${language}`}
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item label="Izoh" name={`description_${language}`}>
        <TextEditor value={value} onChange={setValue} />
      </Form.Item>
    </div>
  )
}

export default EditLanguage
