import { Drawer, Form, Input } from "antd"
import React, { useEffect } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import fileImg from "../../assets/images/fileImg.png"

import styles from "./offerDrawer.module.scss"
import { OfferModel } from "../../utils/models/offerModel"

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  setModalData: React.Dispatch<React.SetStateAction<OfferModel | undefined>>
  modalData: OfferModel | undefined
  openDrawer: boolean
}

const OfferDrawer: React.FC<Props> = ({ openDrawer, setOpenDrawer, modalData }) => {
  const [form] = Form.useForm()
  //handleCloseDrawer
  const handleCloseDrawer = () => {
    setOpenDrawer(false)
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({ ...modalData })
    }
  }, [modalData])

  return (
    <Drawer
      title={
        <div className={styles.drawerTitle}>
          <span>Takliflar</span>
          <CloseIcon onClick={handleCloseDrawer} />
        </div>
      }
      closeIcon={false}
      open={openDrawer}
      onClose={handleCloseDrawer}
      width={544}
    >
      <Form layout="vertical" form={form} className={styles.offerForm}>
        <Form.Item label="Investor ismi" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Kompaniya" name="company">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Telefon raqami" name="phone">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Email address" name="email">
          <Input disabled />
        </Form.Item>
        <p className={styles.drawerIzoh}>Izoh</p>
        <div className={styles.drawerComment}>
          <div className={styles.drawerComment_file}>
            <img src={fileImg} alt="Error" />
          </div>
          <Form.Item name="comment">
            <Input.TextArea disabled />
          </Form.Item>
        </div>
      </Form>
    </Drawer>
  )
}

export default OfferDrawer
