import React, { useEffect, useState } from "react"
import { Button, Form, Modal, Tabs } from "antd"
import { ValidateErrorEntity } from "rc-field-form/lib/interface"

import styles from "./drawers.module.scss"
import TabClassItem from "../tab-item/TabClassItem"
import { FaqClassModel, FaqClassUpdateModal } from "../../utils/models/FaqClassModel"
import { useCreateFaqClass, useUpdateFaqClass } from "../../services/mutation"

type Props = {
  setOpenFaqClass: React.Dispatch<React.SetStateAction<boolean>>
  openFaqClass: boolean
  faqClassData: FaqClassUpdateModal | undefined
  setFaqClassData: React.Dispatch<
    React.SetStateAction<FaqClassUpdateModal | undefined>
  >
}

const FAQClassDrawer: React.FC<Props> = ({
  openFaqClass,
  setOpenFaqClass,
  faqClassData,
  setFaqClassData,
}) => {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("uz")
  const createFaqClass = useCreateFaqClass()
  const updateFaqClass = useUpdateFaqClass(faqClassData?.id)

  //handleChange
  const handleChange = (isActive: string) => {
    setActiveTab(isActive)
  }

  //handleClose
  const handleClose = () => {
    setOpenFaqClass(false)
    setFaqClassData(undefined)
    form.resetFields()
  }

  //onFinishFailed
  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo.errorFields[0].name[0] as string
    const errorTab = name?.slice(name.length - 2)
    setActiveTab(errorTab)
  }

  //onFinish
  const onFinish = (values: FaqClassModel) => {
    if (faqClassData) {
      updateFaqClass.mutateAsync(values).then(handleClose)
    } else {
      createFaqClass.mutateAsync(values).then(handleClose)
    }
  }

  useEffect(() => {
    if (faqClassData) {
      form.setFieldsValue(faqClassData)
    }
  }, [faqClassData])

  return (
    <Modal
      open={openFaqClass}
      onCancel={handleClose}
      title="FAQ sinfi yaratish"
      footer={false}
      width={512}
      className={styles.faqClassDrawer}
    >
      <Form
        layout="vertical"
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        form={form}
        autoComplete="off"
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <TabClassItem language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <TabClassItem language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <TabClassItem language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <TabClassItem language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <TabClassItem language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChange}
        />
        <div className={styles.faqClassDrawer_btns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            htmlType="submit"
            loading={createFaqClass.isLoading || updateFaqClass.isLoading}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default FAQClassDrawer
