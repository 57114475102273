import React, { ReactNode, useState } from "react"
import { Button, Input, Tabs } from "antd"
import { useNavigate } from "react-router"
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon"
import PlusIcon from "../../assets/icons/PlusIcon"
import SearchIcon from "../../assets/icons/SearchIcon"

import styles from "./bodyLayout.module.scss"
import { useQueryParam } from "../../hooks/useQueryParams"

type Props = {
  children?: ReactNode
  title: string
  tabs?: {
    key: string
    label: string
    children?: ReactNode
  }[]
  createButton?: {
    onCreate: () => void
    text: string
  }
}

const BodyLayout: React.FC<Props> = ({ createButton, title, children, tabs }) => {
  const navigate = useNavigate()

  const { searchParams, appendMultipleDifferent } = useQueryParam<any, any>()
  const [search, setSearch] = useState(searchParams.search)

  const changeSearch = (value: string) => {
    setSearch(value)
    appendMultipleDifferent(["search"], [value])
  }

  return (
    <div className={styles.bodyLayout}>
      <header className={styles.bodyLayout_navbar}>
        <ArrowLeftIcon onClick={() => navigate(-1)} />
        <span>{title}</span>
      </header>
      <Tabs className={styles.bodyLayout_tabs} items={tabs} defaultActiveKey="1" />
      <div className={styles.bodyLayout_body}>
        <div className={styles.bodyLayout_body_header}>
          <Input
            placeholder="Qidirmoq"
            prefix={<SearchIcon />}
            value={search}
            onChange={(e) => changeSearch(e.target.value)}
          />
          {createButton && (
            <Button onClick={createButton.onCreate}>
              <PlusIcon />
              {createButton.text}
            </Button>
          )}
        </div>
        <div
          className={
            tabs ? styles.bodyLayout_body_children1 : styles.bodyLayout_body_children
          }
        >
          {children}
        </div>
        {/* <CustopmPagination
          total={20}
          hideOnSinglePage={false}
          showSizeChanger={false}
        /> */}
      </div>
    </div>
  )
}

export default BodyLayout
