import { useQuery } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { queryKeys } from "../utils/constants/queryKeys";
import { InvestmentsModel } from "../utils/models/investmentsModel";
import { SubInvestModels } from "../utils/models/subInvestModel";
import { TagsModel } from "../utils/models/tagsModel";
import { CategoryModels } from "../utils/models/categoryModels";
import { PaginationModel } from "../../../utils/models/PaginationModel";

export function useInvestments(searchParams: string) {
  return useQuery<PaginationModel<InvestmentsModel>>(
    [queryKeys.INVESTITSIYA, searchParams],
    async () => {
      const res = await $api.get(endpoints.INVESTITSIYA + searchParams)
      return res.data
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
    })
}

export function useSubInvestments() {
  return useQuery<PaginationModel<SubInvestModels>>
    ([queryKeys.SUB_INVISTITSIYA], async () => {
      const res = await $api.get(`${endpoints.SUB_INVISTITSIYA}`)
      return res.data
    },
      {
        retry: false,
        refetchOnWindowFocus: false
      })
}

export function useInvestmentTags(searchParams: string) {
  return useQuery<PaginationModel<TagsModel>>
    ([queryKeys.TAGS, searchParams], async () => {
      const res = await $api.get(endpoints.TAGS + searchParams)
      return res.data
    }
    )
}
export function useInvestmentTags1() {
  return useQuery<PaginationModel<TagsModel>>
    ([queryKeys.TAGS], async () => {
      const res = await $api.get(endpoints.TAGS)
      return res.data
    }
    )
}

export function useInvestmentCategory(searchParams: string) {
  return useQuery<PaginationModel<CategoryModels>>
    ([queryKeys.CATEGORY, searchParams], async () => {
      const res = await $api.get(endpoints.CATEGORY + searchParams)
      return res.data
    }
    )
}

export function useInvestmentCategory1() {
  return useQuery<PaginationModel<CategoryModels>>
    ([queryKeys.CATEGORY], async () => {
      const res = await $api.get(`${endpoints.CATEGORY}`)
      return res.data
    }
    )
}


