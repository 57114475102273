import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoint";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { message } from "antd";

// update profile
export const useUpdateProfile = () => {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FormData>(
    async (req) => {
      const res = await $api.put(endpoints.USER, req)
      return res.data
    },
    {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.PROFILE])
        message.success("Muvafaqqiyatli")
      },
      onError: () => {
        message.error("Xatolik")
      }
    }
  )
}