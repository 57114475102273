import React, { useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { Avatar, Button, Form, Input, Upload } from "antd"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import { UserOutlined } from "@ant-design/icons"
import { useUpdateProfile } from "./services/mutations"
import { ProfileFormFields } from "./utils/models/profileFormFields"
import { PhoneInput } from "../../common/phone-input/PhoneInput"
import { useGetProfile } from "./services/queries"
import UploadIcon from "./assets/icons/UploadIcon"
import ArrowLeftIcon from "../../assets/icons/ArrowLeftIcon"

import styles from "./profile.module.scss"
import { rootPaths } from "../../routing/rootPaths"

const Index: React.FC = () => {
  const [form] = Form.useForm()
  const [image, setImage] = useState<Blob | undefined | string>()
  const navigate = useNavigate()
  const { data } = useGetProfile()
  const updateProfile = useUpdateProfile()

  // change
  const handleChange = (e: UploadChangeParam<UploadFile>) => {
    setImage(e?.file?.originFileObj)
  }

  // image source
  const imageSource = () => {
    if (typeof image === "string") return image as string
    return URL.createObjectURL(image as Blob)
  }

  // handle file from event
  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj

    if (file) return file
  }

  //handleNavigatePrev
  const handleNavigatePrev = () => {
    navigate(-1)
  }

  // finish
  const onFinish = (fields: ProfileFormFields) => {
    const formData = new FormData()
    let key: keyof typeof fields

    for (key in fields) {
      if (fields[key] && key !== "image") {
        formData.append(key, fields[key] as string)
      }
    }

    // image
    if (image && typeof image !== "string") {
      formData.append("image", image)
    }

    updateProfile.mutateAsync(formData).then(() => {
      setTimeout(() => {
        navigate(rootPaths.INVESTMENTS)
      }, 2000)
    })
  }

  useEffect(() => {
    if (data) {
      form.setFieldsValue({ ...data })
      if (data?.image) {
        setImage(`${process.env.REACT_APP_API_URL}${data?.image}`)
      }
    }
  }, [data])

  return (
    <div className={styles.profile}>
      <div className={styles.profile_header}>
        <ArrowLeftIcon onClick={handleNavigatePrev} />
        <span>Profile</span>
      </div>
      <div className={styles.profile_body}>
        <Form layout="horizontal" form={form} onFinish={onFinish} autoComplete="off">
          <Form.Item name="FIO" label="FIO" initialValue={data?.FIO}>
            <Input />
          </Form.Item>
          <PhoneInput name="phone" label="Telefon raqami" required={false} />
          <div className={styles.upload}>
            <Form.Item
              label="Sizning suratingiz"
              name={"image"}
              getValueFromEvent={handleFileFromEvent}
            >
              {image ? (
                <img alt="" src={imageSource()} />
              ) : (
                <Avatar icon={<UserOutlined />} size={64} />
              )}
              <Upload
                name="avatar"
                listType="picture-card"
                className={styles.upload_avatar}
                showUploadList={false}
                customRequest={() => null}
                onChange={handleChange}
                accept="image/*"
              >
                <div className={styles.upload_avatar_in}>
                  <UploadIcon />
                  <p>
                    <span>Click to upload</span> or drag and drop
                  </p>
                  <span className={styles.upload_avatar_in_span}>
                    SVG, PNG or JPG (max. 800x400px)
                  </span>
                </div>
              </Upload>
            </Form.Item>
          </div>
          <Form.Item
            label="Login"
            name="username"
            rules={[{ required: true, message: "" }]}
            initialValue={data?.username}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Parol"
            name="password"
            rules={[{ required: true, message: "" }]}
          >
            <Input.Password />
          </Form.Item>
          <div className={styles.profile_body_btns}>
            <Button onClick={handleNavigatePrev}>Bekor qilish</Button>
            <Button htmlType="submit" loading={updateProfile.isLoading}>
              Saqlash
            </Button>
          </div>
        </Form>
      </div>
    </div>
  )
}

export default Index
