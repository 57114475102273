import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { CategoryModels } from "../../investments/utils/models/categoryModels";
import { TagsModel } from "../utils/models/tagsModel";
import { message } from "antd";
import { errorHandler } from "../../../utils/helpers/errorHandler";

export function useCreateSettingsAdmin() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
        const res = await $api.post(endpoints.ADMIN, req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.ADMIN])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useUpdateSettingsAdmin(id: number | undefined) {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
        const res = await $api.put(endpoints.ADMIN + id + "/", req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.ADMIN])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useDeleteSettingsAdmin() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
        const res = await $api.delete(endpoints.ADMIN + id + "/")
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.ADMIN])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useCreateCategory() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, CategoryModels>(async (req) => {
        const res = await $api.post(endpoints.CATEGORY, req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.CATEGORY])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useUpdateCategory(id: number | undefined) {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, CategoryModels>(async (req) => {
        const res = await $api.put(endpoints.CATEGORY + id + "/", req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.CATEGORY])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useDeleteCategory() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
        const res = await $api.delete(endpoints.CATEGORY + id + "/")
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.CATEGORY])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useCreateTag() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, TagsModel>(async (req) => {
        const res = await $api.post(endpoints.TAGS, req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.TAGS])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useUpdateTag(id: number | undefined) {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, TagsModel>(async (req) => {
        const res = await $api.put(endpoints.TAGS + id + "/", req)
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.TAGS])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}

export function useDeleteTag() {
    const qc = useQueryClient()
    return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
        const res = await $api.delete(endpoints.TAGS + id + "/")
        return res.data
    }, {
        onSuccess: () => {
            qc.invalidateQueries([queryKeys.TAGS])
            message.success('Muvafaqqiyatli');
        },
        onError: errorHandler
    })
}