import React from "react"
import { Navigate, Outlet, useLocation } from "react-router-dom"
import { rootPaths } from "../../routing/rootPaths"
import { LocalStorage } from "../../services/LocalStorage"
import { TOKEN } from "../../utils/constants/localStorageKeys"

const RequireAuth: React.FC = () => {
  const location = useLocation()
  const isAuth = LocalStorage.get(TOKEN)

  return isAuth ? (
    <Outlet />
  ) : (
    <Navigate to={rootPaths.AUTH} state={{ from: location }} replace />
  )
}

export default RequireAuth
