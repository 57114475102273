import { useMutation, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { AdminModel } from "../utils/models/adminModel";
import { PaginationModel } from "../../../utils/models/PaginationModel";

export function useSettingsAdmin(searchParams: string) {

  return useQuery<PaginationModel<AdminModel>>
    ([queryKeys.ADMIN, searchParams], async () => {
      const res = await $api.get(endpoints.ADMIN + searchParams)
      return res.data
    })
}

export function useGetProfileData(id: number) {
  return useMutation<AdminModel>
    ([queryKeys.ADMIN], async () => {
      const res = await $api.get(endpoints.ADMIN + id + "/")
      return res.data
    })
}

