import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { InvestorCommentModel } from "../utils/models/investorCommentModel";
import { PaginationModel } from "../../../utils/models/PaginationModel";

export function useInvestorComments(searchParams: string) {
    return useQuery<PaginationModel<InvestorCommentModel>>
        ([queryKeys.INVESTOR_COMMENTS, searchParams], async () => {
            const res = await $api.get(endpoints.INVESTOR_COMMENT + searchParams)
            return res.data
        })
}