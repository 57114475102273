import React from "react"

const FossilsIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3365 0.830162C4.94153 0.832037 4.54843 0.869209 4.16021 0.94435C9.17115 3.96062 12.9129 7.99247 15.8658 12.6558C15.9842 10.1358 14.9719 7.54032 13.3565 5.42532C12.6957 5.67366 11.881 5.4601 11.3189 4.89797C10.7574 4.33641 10.5428 3.52233 10.7901 2.86182C9.11537 1.59821 7.20381 0.820787 5.3365 0.830162ZM23.1753 1.78665L19.8502 5.42532L21.2593 11.3111L18.6768 11.7226L17.7671 15.502L21.2125 17.3359L19.2789 19.958L20.6691 22.022L16.5074 20.7271L17.2809 18.6719L13.6862 17.0855L14.3013 13.1216L9.08064 15.8462L8.88282 19.5142L3.62251 18.2178L2.06392 23.1001H23.1753V1.78665H23.1753ZM12.2417 2.75204C12.2236 2.75251 12.2054 2.75335 12.1876 2.75485C12.0167 2.77008 11.865 2.83435 11.7569 2.94235C11.4687 3.23063 11.4875 3.82735 11.9385 4.27829C12.3895 4.72937 12.9863 4.74816 13.2745 4.45997C13.5626 4.17179 13.5438 3.57507 13.0928 3.12404C12.8286 2.8598 12.5146 2.74458 12.2417 2.75204ZM9.211 5.98046L1.18506 14.0093C1.38536 14.5683 1.70195 14.996 2.36865 15.2105L10.4049 7.17141C10.0395 6.75469 9.64046 6.35888 9.211 5.98046ZM16.6538 6.52538L17.0904 10.495L18.3765 6.52533H16.6539L16.6538 6.52538ZM10.4649 10.0395L9.52009 11.7241L13.8648 12.0698L10.4649 10.0395L10.4649 10.0395Z"
        fill="#667085"
      />
    </svg>
  )
}

export default FossilsIcon
