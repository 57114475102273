import React, { useState, useEffect } from "react"
import { Button, Drawer, Form, Tabs, Upload } from "antd"

import styles from "./index.module.scss"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import EditLanguage from "../../../investments/components/languages/EditLanguage"
import FileIcon from "../../../../assets/icons/FileIcon"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import { CreateIndustryModel, IndustryModel } from "../../utils/models/IndustryModel"
import { useCreateIndustry, useUpdateIndustry } from "../../service/mutation"
import { ValidateErrorEntity } from "rc-field-form/es/interface"

type Props = {
  openDrawer: boolean
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  modalData: IndustryModel | undefined
  setModalData: React.Dispatch<React.SetStateAction<IndustryModel | undefined>>
}

const { Dragger } = Upload

const Index: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  modalData,
  setModalData,
}) => {
  const [form] = Form.useForm()
  const [activeTab, setActiveTab] = useState("uz")
  const createIndustry = useCreateIndustry()
  const updateIndustry = useUpdateIndustry(modalData?.id)

  //handleChangeTabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  //handle close drawer
  const handleClose = () => {
    setOpenDrawer(false)
    form.resetFields()
    setModalData(undefined)
    setActiveTab("uz")
  }

  useEffect(() => {
    if (modalData) {
      form.setFieldsValue({
        ...modalData,
      })
    }
  }, [modalData])

  //on finish
  const onFinish = (values: CreateIndustryModel) => {
    const sendData = {
      ...values,
    }
    const formData = new FormData()
    const image = values.image?.fileList?.map((item) => item?.originFileObj)

    Object.keys(sendData).forEach((element) => {
      if (sendData[element as keyof typeof sendData] && element !== "image") {
        formData.append(element, sendData[element as keyof typeof sendData] as any)
      }
    })

    image &&
      image?.forEach((item: any) => {
        formData.append("image", item)
      })
    if (modalData) {
      updateIndustry.mutateAsync(formData).then(handleClose)
    } else {
      createIndustry.mutateAsync(formData).then(handleClose)
    }
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0]?.name[0] as string
    const tabs = ["uz", "ru", "xi", "en", "ar"]
    const errorTab = name.slice(name.length - 2)

    if (tabs.includes(errorTab)) {
      setActiveTab(errorTab)
    }

    setTimeout(() => {
      document
        .getElementById(name)
        ?.scrollIntoView({ block: "center", behavior: "smooth" })
    }, 200)
  }

  return (
    <Drawer
      open={openDrawer}
      onClose={handleClose}
      title={
        <div className={styles.drawerTitle}>
          <span>Yangi qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
      width={544}
    >
      <Form
        form={form}
        layout="vertical"
        autoComplete="off"
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <EditLanguage language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <EditLanguage language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <EditLanguage language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <EditLanguage language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <EditLanguage language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChangeTabs}
        />
        <Form.Item label="Rasm" name="image">
          <Dragger
            className={styles.innovationUpload}
            iconRender={() => <FileIcon />}
            accept=".png, .svg, .jpg, .jpeg, .gif, .ico"
            customRequest={() => null}
          >
            <UploadIcon />
            <p className="ant-upload-text">
              <span>Click to upload</span> or drag and drop
            </p>
            <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </Dragger>
        </Form.Item>
        <div className={styles.footer}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            loading={createIndustry?.isLoading || updateIndustry?.isLoading}
            htmlType="submit"
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default Index
