import React from "react"
import { BrowserRouter } from "react-router-dom"

import RootRouter from "./routing/RootRouter"
import { QueryClientProvider, QueryClient } from "@tanstack/react-query"
import { ConfigProvider } from "antd"

import "./styles/main.scss"
import "./styles/common.scss"

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              borderRadius: 8,
              fontSize: 16,
              controlHeight: 44,
            },
            Input: {
              controlHeight: 44,
              colorBorder: "#D0D5DD",
              borderRadius: 8,
            },
            Form: {
              margin: 20,
            },
            Pagination: {
              borderRadius: 8,
              colorText: "#344054",
              fontSize: 14,
            },
            Select: {
              borderRadius: 8,
              colorBorder: "#D0D5DD",
              controlHeight: 44,
            },
          },
        }}
      >
        <BrowserRouter>
          <RootRouter />
        </BrowserRouter>
      </ConfigProvider>
    </QueryClientProvider>
  )
}

export default App
