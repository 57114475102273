import React, { useState, useEffect } from "react"
import { Button, Drawer, Form, Input, Switch, Tabs, Upload } from "antd"
import CloseIcon from "../../../../assets/icons/CloseIcon"
import EditLanguage from "../../../investments/components/languages/EditLanguage"
import { ValidateErrorEntity } from "rc-field-form/es/interface"

import styles from "./createFossil.module.scss"
import UploadIcon from "../../../../assets/icons/UploadIcon"
import FileIcon from "../../../../assets/icons/FileIcon"
import { UploadChangeParam, UploadFile } from "antd/es/upload"
import { localeFormatter } from "../../../../hooks/localeFormatter"
import {
  useCreateFossil,
  useCreateMines,
  useDeleteQazilmaProduct,
  useSendFilePath,
  useUpdateFossil,
  useUpdateMines,
} from "../../service/mutation"
import { FossilModel } from "../../utils/models/FossilsModel"
import { parseLocaledString } from "../../../../utils/helpers/parceLocaledString"
import { CreateFossilModel2 } from "../../utils/models/CreateFossilModel2"
import DeleteIcon from "../../../../assets/icons/DeleteIcon"
import { useGetCities } from "../../service/queries"

type Props = {
  openDrawer: boolean
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  drawerData: FossilModel | undefined
  setDrawerData: React.Dispatch<React.SetStateAction<FossilModel | undefined>>
}

type FileModel = {
  file?: string
  image?: File | undefined
  name?: { uz?: string; ru?: string; en?: string; xi?: string; ar?: string }
}

const { Dragger } = Upload

const CreateFossil: React.FC<Props> = ({
  openDrawer,
  setOpenDrawer,
  drawerData,
  setDrawerData,
}) => {
  const [activeTab, setActiveTab] = useState("uz")
  const [images, setImages] = useState<FileModel[]>([])
  const [form] = Form.useForm()
  const cities = useGetCities()
  const createFossil = useCreateFossil()
  const createMine = useCreateMines()
  const updateMine = useUpdateMines()
  const sendFile = useSendFilePath()
  const updateFossil = useUpdateFossil(Number(drawerData?.id))
  const deleteProduct = useDeleteQazilmaProduct()

  const handleDeleteProduct = (id: number) => {
    deleteProduct.mutate(id)
  }

  const handleSendFile = (file: File) => {
    const formData = new FormData()
    formData.append("image", file)
    sendFile.mutateAsync(formData).then((req: any) => {
      setImages([...images, { file: req.image }])
    })
  }

  //handleClose
  const handleClose = () => {
    setOpenDrawer(false)
    setImages([])
    setDrawerData(undefined)
    form.resetFields()
  }

  //handleChangeTabs
  const handleChangeTabs = (activeKey: string) => {
    setActiveTab(activeKey)
  }

  useEffect(() => {
    if (drawerData) {
      form.setFieldsValue({
        ...drawerData,
        city: cities?.data?.filter((item) => drawerData?.city === item?.id)[0].name,
      })
    }
  }, [drawerData])

  const handleFileFromEvent = (e: UploadChangeParam<UploadFile>) => {
    const file = e?.file?.originFileObj
    if (file) return file
  }

  const handlePriceFields = (field: string) => {
    const value = form.getFieldValue([field])

    form.setFieldsValue({
      [field]: localeFormatter(value),
    })
  }
  const handlePriceFields2 = (field: string) => {
    const value = form.getFieldValue([field])

    form.setFieldsValue({
      [field]: value,
    })
  }

  const onFinishFailed = (errorInfo: ValidateErrorEntity) => {
    const name = errorInfo?.errorFields[0]?.name[0] as string
    const tabs = ["uz", "ru", "xi", "en", "ar"]
    const errorTab = name.slice(name.length - 2)

    if (tabs.includes(errorTab)) {
      setActiveTab(errorTab)
    }

    setTimeout(() => {
      document
        .getElementById(name)
        ?.scrollIntoView({ block: "center", behavior: "smooth" })
    }, 200)
  }

  const onFinish = (values: CreateFossilModel2) => {
    const countAndBackup = {
      exist_count: values?.exist_count
        ? parseLocaledString(String(values?.exist_count))
        : 0,
      exist_backup: values?.exist_backup
        ? parseLocaledString(String(values?.exist_backup))
        : 0,
      valid_count: values?.valid_count
        ? parseLocaledString(String(values?.valid_count))
        : 0,
      valid_backup: values?.valid_backup
        ? parseLocaledString(String(values?.valid_backup))
        : 0,
      invalid_count: values?.invalid_count
        ? parseLocaledString(String(values?.invalid_count))
        : 0,
      invalid_backup: values?.invalid_backup
        ? parseLocaledString(String(values?.invalid_backup))
        : 0,
    }

    if (drawerData) {
      const products = [
        ...drawerData.products,
        ...(images?.map((item) => ({
          file: item?.file,
          name_uz: item?.name?.uz,
          name_ru: item?.name?.ru,
          name_en: item?.name?.en,
          name_xi: item?.name?.xi,
          name_ar: item?.name?.ar,
          qazilma: Number(drawerData?.id),
        })) as any),
      ]
      const newData = {
        ...values,
        city: drawerData?.city,
        image: typeof values?.image === "string" ? "" : values?.image,
        countAndBackup,
      }
      updateFossil.mutateAsync(newData).then((res: any) => {
        const newData: any = {
          products: products?.map((item) => ({
            file: item?.file,
            name_uz: item?.name_uz,
            name_ru: item?.name_ru,
            name_en: item?.name_en,
            name_xi: item?.name_xi,
            name_ar: item?.name_ar,
            qazilma: Number(res?.id),
          })),
        }
        updateMine.mutateAsync(newData)
        handleClose()
      })
    } else {
      const sendData = {
        ...values,
        exist_count: values?.exist_count
          ? parseLocaledString(String(values?.exist_count))
          : 0,
        exist_backup: values?.exist_backup
          ? parseLocaledString(String(values?.exist_backup))
          : 0,
        valid_count: values?.valid_count
          ? parseLocaledString(String(values?.valid_count))
          : 0,
        valid_backup: values?.valid_backup
          ? parseLocaledString(String(values?.valid_backup))
          : 0,
        invalid_count: values?.invalid_count
          ? parseLocaledString(String(values?.invalid_count))
          : 0,
        invalid_backup: values?.invalid_backup
          ? parseLocaledString(String(values?.invalid_backup))
          : 0,
        image: values?.image === values?.name_uz ? "" : values?.image,
        // countAndBackup,
      }
      createFossil.mutateAsync(sendData).then((res: any) => {
        const newData: any = {
          products: images?.map((item) => ({
            file: item?.file,
            name_uz: item?.name?.uz,
            name_ru: item?.name?.ru,
            name_en: item?.name?.en,
            name_xi: item?.name?.xi,
            name_ar: item?.name?.ar,
            qazilma: Number(res?.id),
          })),
        }
        createMine.mutateAsync(newData)
        handleClose()
      })
    }
  }

  const removeElement = (id: number) => {
    const newArr = images?.filter((prev, index) => index !== id)
    setImages(newArr)
  }

  return (
    <Drawer
      open={openDrawer}
      onClose={handleClose}
      width={620}
      title={
        <div className={styles.title}>
          <span>Qazilma qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
    >
      <Form
        form={form}
        onFinishFailed={onFinishFailed}
        onFinish={onFinish}
        layout="vertical"
        autoComplete="off"
      >
        <Tabs
          activeKey={activeTab}
          items={[
            {
              key: "uz",
              label: "O'zbek",
              children: <EditLanguage language="uz" />,
              forceRender: true,
            },
            {
              key: "ru",
              label: "Rus",
              children: <EditLanguage language="ru" />,
              forceRender: true,
            },
            {
              key: "en",
              label: "Ingliz",
              children: <EditLanguage language="en" />,
              forceRender: true,
            },
            {
              key: "xi",
              label: "Xitoy",
              children: <EditLanguage language="xi" />,
              forceRender: true,
            },
            {
              key: "ar",
              label: "Arab",
              children: <EditLanguage language="ar" />,
              forceRender: true,
            },
          ]}
          onChange={handleChangeTabs}
        />
        <div className={styles.formTop}>
          <Form.Item valuePropName="checked" name={"ruda"} label="Metall?">
            <Switch onChange={() => handlePriceFields2("ruda")} />
          </Form.Item>
          <Form.Item name={"fields"} label="Qazilma mavjud hududlar soni">
            <Input onChange={() => handlePriceFields("fields")} />
          </Form.Item>
        </div>
        <Form.Item label="Rasm" name="image" getValueFromEvent={handleFileFromEvent}>
          <Dragger
            className={styles.innovationUpload}
            multiple
            iconRender={() => <FileIcon />}
            accept=".png, .svg, .jpg, .jpeg, .gif, .ico"
            customRequest={() => null}
            maxCount={1}
          >
            <UploadIcon />
            <p className="ant-upload-text">
              <span>Click to upload</span> or drag and drop
            </p>
            <p className="ant-upload-hint">SVG, PNG, JPG or GIF (max. 800x400px)</p>
          </Dragger>
        </Form.Item>
        <div className={styles.info}>
          <div className={styles.info_header}>
            <span>Holati</span>
            <span>Nechta</span>
            <span>Zaxirasi</span>
          </div>
          <div className={styles.info_counts}>
            <span>Mavjud konlari</span>
            <Form.Item name="exist_count">
              <Input
                defaultValue={0}
                onChange={() => handlePriceFields("exist_count")}
              />
            </Form.Item>
            <Form.Item name="exist_backup">
              <Input
                defaultValue={0}
                addonAfter="-mln.m3"
                onChange={() => handlePriceFields("exist_backup")}
              />
            </Form.Item>
          </div>
          <div className={styles.info_counts}>
            <span>Amaldagi konlari</span>
            <Form.Item name="valid_count">
              <Input
                defaultValue={0}
                onChange={() => handlePriceFields("valid_count")}
              />
            </Form.Item>
            <Form.Item name="valid_backup">
              <Input
                defaultValue={0}
                addonAfter="-mln.m3"
                onChange={() => handlePriceFields("valid_backup")}
              />
            </Form.Item>
          </div>
          <div className={styles.info_counts}>
            <span>Foydalanilmayotgan konlari</span>
            <Form.Item name="invalid_count">
              <Input
                defaultValue={0}
                onChange={() => handlePriceFields("invalid_count")}
              />
            </Form.Item>
            <Form.Item name="invalid_backup">
              <Input
                defaultValue={0}
                addonAfter="-mln.m3"
                onChange={() => handlePriceFields("invalid_backup")}
              />
            </Form.Item>
          </div>
        </div>
        <div className={styles.upload2}>
          <span>Ishlab chiqarilgan maxsulotlar</span>
          <div className={styles.upload2_items}>
            <Form.Item name={"image"}>
              <div className={styles.products}>
                <div className={styles.products_newProd}>
                  {images &&
                    images?.map((item, index) => {
                      return (
                        <div className={styles.products_newProd_item}>
                          <div className={styles.products_newProd_item_img}>
                            <img
                              src={
                                item?.file
                                  ? process.env.REACT_APP_API_URL +
                                    "/media/" +
                                    item?.file
                                  : ""
                              }
                            />
                            <div className={styles.products_newProd_item_img_hover}>
                              <DeleteIcon onClick={() => removeElement(index)} />
                            </div>
                          </div>
                          <Input
                            value={
                              item?.name
                                ? item?.name[activeTab as keyof typeof item.name]
                                : ""
                            }
                            onChange={(e) => {
                              setImages((prev) =>
                                prev?.map((el, i) => ({
                                  ...el,
                                  name: {
                                    ...el?.name,
                                    [activeTab as keyof typeof el.name]:
                                      i === index
                                        ? e.target.value
                                        : el.name![
                                            activeTab as keyof typeof el.name
                                          ] || "",
                                  },
                                }))
                              )
                            }}
                          />
                        </div>
                      )
                    })}
                  {drawerData?.products &&
                    drawerData?.products?.map((item, index) => {
                      return (
                        <div className={styles.products_newProd_item}>
                          <div className={styles.products_newProd_item_img}>
                            <img
                              src={
                                item?.file
                                  ? process.env.REACT_APP_API_URL +
                                    "/media/" +
                                    item?.file
                                  : ""
                              }
                            />
                            <div className={styles.products_newProd_item_img_hover}>
                              <DeleteIcon
                                onClick={() => {
                                  handleDeleteProduct(item?.id)
                                }}
                              />
                            </div>
                          </div>
                          <Input
                            value={item[`name_${activeTab}` as keyof typeof item]}
                            onChange={(e) => {
                              setDrawerData((prev) => {
                                return {
                                  ...prev!,
                                  products: [
                                    ...prev!.products.map((item, i) => {
                                      return i === index
                                        ? {
                                            ...item,
                                            [`name_${activeTab}`]: e.target.value,
                                          }
                                        : item
                                    }),
                                  ],
                                }
                              })
                              setImages((prev) =>
                                prev?.map((el, i) => ({
                                  ...el,
                                  name: {
                                    ...el?.name,
                                    [activeTab as keyof typeof el.name]:
                                      i === index
                                        ? e.target.value
                                        : el.name![
                                            activeTab as keyof typeof el.name
                                          ] || "",
                                  },
                                }))
                              )
                            }}
                          />
                        </div>
                      )
                    })}
                </div>
                <label className={styles.innovationUpload}>
                  <UploadIcon />
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleSendFile(e.target.files![0])
                      setImages((prev) => [...prev, { image: e.target.files![0] }])
                      e.target.value = ""
                    }}
                  />
                </label>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className={styles.btns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button
            htmlType="submit"
            loading={createFossil?.isLoading || updateFossil?.isLoading}
          >
            Saqlash
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default CreateFossil
