import React from "react"

const LogoIcon = () => {
  return (
    <svg
      width="204"
      height="40"
      viewBox="0 0 204 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.5803 30.366C25.3053 30.366 29.9464 25.725 29.9464 20C29.9464 14.275 25.3053 9.63394 19.5803 9.63394C13.8553 9.63394 9.21428 14.275 9.21428 20C9.21428 25.725 13.8553 30.366 19.5803 30.366ZM19.5803 23.4554C21.4887 23.4554 23.0357 21.9083 23.0357 20C23.0357 18.0917 21.4887 16.5447 19.5803 16.5447C17.672 16.5447 16.125 18.0917 16.125 20C16.125 21.9083 17.672 23.4554 19.5803 23.4554Z"
        fill="#17AB59"
      />
      <circle cx="36.8571" cy="12.8013" r="2.30357" fill="#17AB59" />
      <circle cx="29.9464" cy="9.63394" r="3.45535" fill="#17AB59" />
      <circle cx="26.779" cy="2.72324" r="2.30357" fill="#17AB59" />
      <circle cx="19.5803" cy="5.02681" r="3.45535" fill="#17AB59" />
      <circle cx="12.6696" cy="2.72324" r="2.30357" fill="#17AB59" />
      <circle cx="9.21426" cy="9.63394" r="3.45535" fill="#17AB59" />
      <circle cx="2.59151" cy="12.8013" r="2.59151" fill="#17AB59" />
      <circle cx="2.59151" cy="26.9107" r="2.59151" fill="#17AB59" />
      <circle cx="12.6696" cy="36.9888" r="2.30357" fill="#17AB59" />
      <circle cx="26.779" cy="37.2768" r="2.30357" fill="#17AB59" />
      <circle cx="36.8571" cy="27.1986" r="2.30357" fill="#17AB59" />
      <circle cx="4.89508" cy="20" r="3.45535" fill="#17AB59" />
      <circle cx="9.21426" cy="30.366" r="3.45535" fill="#17AB59" />
      <circle cx="19.5803" cy="34.6852" r="3.45535" fill="#17AB59" />
      <circle cx="29.9464" cy="30.366" r="3.45535" fill="#17AB59" />
      <circle cx="34.5535" cy="20" r="3.45535" fill="#17AB59" />
      <path
        d="M63.2942 8.95188V27.2146H59.9429L51.959 15.7201H51.8246V27.2146H47.9447V8.95188H51.3497L59.2709 20.4374H59.4322V8.95188H63.2942Z"
        fill="#009241"
      />
      <path
        d="M70.3529 27.4732C69.4747 27.4732 68.6922 27.3216 68.0052 27.0184C67.3182 26.7093 66.7746 26.2545 66.3744 25.654C65.9801 25.0477 65.783 24.2927 65.783 23.389C65.783 22.6281 65.9234 21.989 66.2041 21.4718C66.4849 20.9546 66.8672 20.5385 67.3511 20.2234C67.835 19.9083 68.3845 19.6705 68.9998 19.51C69.6211 19.3495 70.2722 19.2365 70.9532 19.1711C71.7537 19.0879 72.3989 19.0106 72.8887 18.9393C73.3786 18.862 73.734 18.7491 73.955 18.6004C74.1761 18.4518 74.2866 18.2318 74.2866 17.9405V17.887C74.2866 17.3223 74.1074 16.8853 73.7489 16.5762C73.3965 16.2671 72.8947 16.1125 72.2436 16.1125C71.5566 16.1125 71.01 16.2641 70.6038 16.5673C70.1976 16.8645 69.9288 17.239 69.7973 17.6909L66.2669 17.4055C66.4461 16.5732 66.7985 15.8539 67.3242 15.2475C67.8499 14.6352 68.5279 14.1655 69.3583 13.8386C70.1946 13.5057 71.1623 13.3392 72.2615 13.3392C73.0261 13.3392 73.7579 13.4284 74.4568 13.6067C75.1617 13.7851 75.786 14.0615 76.3296 14.436C76.8792 14.8106 77.3123 15.2921 77.6289 15.8806C77.9455 16.4632 78.1038 17.1618 78.1038 17.9762V27.2146H74.4837V25.3152H74.3762C74.1552 25.7432 73.8595 26.1207 73.4891 26.4477C73.1187 26.7687 72.6737 27.0214 72.154 27.2057C71.6342 27.384 71.0339 27.4732 70.3529 27.4732ZM71.4461 24.8515C72.0076 24.8515 72.5034 24.7415 72.9335 24.5215C73.3636 24.2956 73.7012 23.9924 73.9461 23.612C74.191 23.2315 74.3135 22.8005 74.3135 22.319V20.8654C74.194 20.9427 74.0297 21.0141 73.8206 21.0794C73.6175 21.1389 73.3875 21.1954 73.1307 21.2489C72.8738 21.2964 72.6169 21.341 72.3601 21.3826C72.1032 21.4183 71.8702 21.451 71.6611 21.4807C71.2131 21.5461 70.8218 21.6502 70.4873 21.7928C70.1528 21.9355 69.8929 22.1287 69.7077 22.3725C69.5225 22.6103 69.4299 22.9075 69.4299 23.2642C69.4299 23.7814 69.6181 24.1767 69.9945 24.4502C70.3768 24.7177 70.8607 24.8515 71.4461 24.8515Z"
        fill="#009241"
      />
      <path
        d="M93.404 13.5175L88.5922 27.2146H84.2911L79.4792 13.5175H83.5115L86.3699 23.3177H86.5133L89.3628 13.5175H93.404Z"
        fill="#009241"
      />
      <path
        d="M101.132 27.4821C99.7406 27.4821 98.5369 27.1878 97.5214 26.5993C96.5118 26.0048 95.7322 25.1784 95.1827 24.1203C94.6331 23.0561 94.3583 21.8226 94.3583 20.4196C94.3583 19.0047 94.6331 17.7681 95.1827 16.71C95.7322 15.6458 96.5118 14.8195 97.5214 14.2309C98.5369 13.6364 99.7406 13.3392 101.132 13.3392C102.524 13.3392 103.725 13.6364 104.735 14.2309C105.75 14.8195 106.533 15.6458 107.082 16.71C107.632 17.7681 107.907 19.0047 107.907 20.4196C107.907 21.8226 107.632 23.0561 107.082 24.1203C106.533 25.1784 105.75 26.0048 104.735 26.5993C103.725 27.1878 102.524 27.4821 101.132 27.4821ZM101.15 24.5394C101.784 24.5394 102.312 24.361 102.736 24.0043C103.161 23.6417 103.48 23.1483 103.695 22.5241C103.916 21.8998 104.027 21.1894 104.027 20.3928C104.027 19.5962 103.916 18.8858 103.695 18.2616C103.48 17.6374 103.161 17.1439 102.736 16.7813C102.312 16.4187 101.784 16.2373 101.15 16.2373C100.511 16.2373 99.9736 16.4187 99.5375 16.7813C99.1074 17.1439 98.7818 17.6374 98.5608 18.2616C98.3458 18.8858 98.2382 19.5962 98.2382 20.3928C98.2382 21.1894 98.3458 21.8998 98.5608 22.5241C98.7818 23.1483 99.1074 23.6417 99.5375 24.0043C99.9736 24.361 100.511 24.5394 101.15 24.5394Z"
        fill="#009241"
      />
      <path
        d="M110.389 27.2146V13.5175H114.206V27.2146H110.389ZM112.306 11.7519C111.739 11.7519 111.252 11.5647 110.846 11.1901C110.446 10.8097 110.245 10.3549 110.245 9.82578C110.245 9.30263 110.446 8.85379 110.846 8.47926C111.252 8.09879 111.739 7.90855 112.306 7.90855C112.874 7.90855 113.358 8.09879 113.758 8.47926C114.164 8.85379 114.367 9.30263 114.367 9.82578C114.367 10.3549 114.164 10.8097 113.758 11.1901C113.358 11.5647 112.874 11.7519 112.306 11.7519Z"
        fill="#009241"
      />
      <path
        d="M119.62 32.3509C119.137 32.3509 118.683 32.3123 118.258 32.235C117.84 32.1637 117.494 32.0715 117.219 31.9586L118.079 29.1229C118.527 29.2596 118.93 29.3339 119.289 29.3458C119.653 29.3577 119.967 29.2745 120.23 29.0961C120.499 28.9178 120.717 28.6146 120.884 28.1866L121.108 27.6069L116.171 13.5175H120.185L123.034 23.5763H123.178L126.054 13.5175H130.095L124.746 28.6948C124.489 29.432 124.14 30.0741 123.697 30.621C123.261 31.1739 122.709 31.5989 122.04 31.8962C121.371 32.1994 120.564 32.3509 119.62 32.3509Z"
        fill="#009241"
      />
      <path d="M135.66 8.95188V27.2146H132.335V8.95188H135.66Z" fill="#393939" />
      <path
        d="M142.36 19.189V27.2146H139.116V13.5175H142.217V15.845H142.378C142.695 15.0781 143.199 14.4687 143.892 14.0169C144.591 13.5651 145.455 13.3392 146.482 13.3392C147.432 13.3392 148.259 13.5413 148.964 13.9456C149.675 14.3498 150.225 14.9354 150.613 15.7023C151.007 16.4692 151.201 17.3996 151.195 18.4934V27.2146H147.952V18.9928C147.952 18.0773 147.713 17.3609 147.235 16.8437C146.763 16.3265 146.109 16.0679 145.272 16.0679C144.705 16.0679 144.2 16.1927 143.758 16.4424C143.322 16.6862 142.978 17.0399 142.728 17.5036C142.483 17.9673 142.36 18.5291 142.36 19.189Z"
        fill="#393939"
      />
      <path
        d="M166.294 13.5175L161.392 27.2146H157.808L152.907 13.5175H156.366L159.529 23.6922H159.672L162.844 13.5175H166.294Z"
        fill="#393939"
      />
      <path
        d="M174.163 27.4821C172.784 27.4821 171.592 27.1967 170.588 26.626C169.591 26.0494 168.823 25.2349 168.285 24.1827C167.748 23.1245 167.479 21.879 167.479 20.4463C167.479 19.0374 167.748 17.8008 168.285 16.7367C168.829 15.6666 169.588 14.8343 170.561 14.2399C171.535 13.6394 172.679 13.3392 173.993 13.3392C174.842 13.3392 175.642 13.4759 176.395 13.7494C177.153 14.0169 177.822 14.4331 178.402 14.9978C178.987 15.5626 179.447 16.2819 179.782 17.1558C180.116 18.0238 180.284 19.0582 180.284 20.2591V21.2489H170.696V19.073H177.174C177.168 18.4548 177.034 17.9049 176.771 17.4233C176.508 16.9359 176.141 16.5524 175.669 16.273C175.203 15.9936 174.659 15.8539 174.038 15.8539C173.375 15.8539 172.793 16.0144 172.291 16.3354C171.789 16.6505 171.398 17.0666 171.117 17.5839C170.842 18.0951 170.702 18.6569 170.696 19.2692V21.1686C170.696 21.9652 170.842 22.6489 171.135 23.2196C171.428 23.7844 171.837 24.2183 172.362 24.5215C172.888 24.8188 173.503 24.9674 174.208 24.9674C174.68 24.9674 175.107 24.902 175.49 24.7712C175.872 24.6345 176.204 24.4353 176.484 24.1738C176.765 23.9122 176.977 23.5882 177.12 23.2018L180.149 23.5406C179.958 24.3372 179.594 25.0328 179.056 25.6273C178.524 26.2158 177.843 26.6736 177.013 27.0005C176.183 27.3216 175.233 27.4821 174.163 27.4821Z"
        fill="#393939"
      />
      <path
        d="M193.906 17.138L190.949 17.459C190.865 17.1618 190.719 16.8824 190.51 16.6208C190.307 16.3592 190.032 16.1482 189.685 15.9876C189.339 15.8271 188.915 15.7469 188.413 15.7469C187.738 15.7469 187.171 15.8925 186.711 16.1838C186.257 16.4751 186.033 16.8526 186.039 17.3163C186.033 17.7146 186.179 18.0386 186.478 18.2883C186.782 18.538 187.284 18.7431 187.983 18.9036L190.331 19.403C191.633 19.6824 192.601 20.1253 193.234 20.7317C193.873 21.338 194.196 22.1317 194.202 23.1126C194.196 23.9746 193.942 24.7355 193.44 25.3954C192.944 26.0494 192.254 26.5606 191.37 26.9292C190.486 27.2978 189.47 27.4821 188.323 27.4821C186.639 27.4821 185.283 27.1313 184.255 26.4298C183.228 25.7224 182.616 24.7385 182.418 23.4782L185.582 23.175C185.725 23.7933 186.03 24.26 186.496 24.575C186.961 24.8901 187.568 25.0477 188.314 25.0477C189.085 25.0477 189.703 24.8901 190.169 24.575C190.641 24.26 190.877 23.8706 190.877 23.4069C190.877 23.0145 190.725 22.6905 190.42 22.4349C190.122 22.1792 189.656 21.9831 189.022 21.8463L186.675 21.3559C185.355 21.0824 184.378 20.6217 183.745 19.9737C183.111 19.3198 182.798 18.4934 182.804 17.4947C182.798 16.6505 183.028 15.9193 183.494 15.301C183.966 14.6768 184.62 14.1953 185.456 13.8564C186.298 13.5116 187.269 13.3392 188.368 13.3392C189.981 13.3392 191.251 13.681 192.177 14.3647C193.108 15.0484 193.685 15.9728 193.906 17.138Z"
        fill="#393939"
      />
      <path
        d="M203.749 13.5175V16.0144H201.034V13.5175H203.749ZM197.79 10.236H201.034V23.0948C201.034 23.5287 201.1 23.8616 201.231 24.0935C201.369 24.3194 201.548 24.474 201.769 24.5572C201.99 24.6404 202.235 24.682 202.504 24.682C202.707 24.682 202.892 24.6672 203.059 24.6375C203.232 24.6077 203.364 24.581 203.453 24.5572L204 27.0808C203.827 27.1403 203.579 27.2056 203.256 27.277C202.94 27.3483 202.551 27.3899 202.091 27.4018C201.279 27.4256 200.547 27.3037 199.896 27.0362C199.245 26.7628 198.728 26.3407 198.346 25.77C197.97 25.1993 197.784 24.4859 197.79 23.6298V10.236Z"
        fill="#393939"
      />
    </svg>
  )
}

export default LogoIcon
