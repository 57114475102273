import React from "react"

const FileIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="2" y="2" width="32" height="32" rx="16" fill="#D1E4FC" />
      <path
        d="M19.3337 11.5129V14.2666C19.3337 14.64 19.3337 14.8266 19.4063 14.9692C19.4702 15.0947 19.5722 15.1967 19.6977 15.2606C19.8403 15.3333 20.027 15.3333 20.4003 15.3333H23.154M23.3337 16.6587V21.4666C23.3337 22.5867 23.3337 23.1467 23.1157 23.5746C22.9239 23.9509 22.618 24.2569 22.2416 24.4486C21.8138 24.6666 21.2538 24.6666 20.1337 24.6666H15.867C14.7469 24.6666 14.1868 24.6666 13.759 24.4486C13.3827 24.2569 13.0767 23.9509 12.885 23.5746C12.667 23.1467 12.667 22.5867 12.667 21.4666V14.5333C12.667 13.4131 12.667 12.8531 12.885 12.4253C13.0767 12.0489 13.3827 11.743 13.759 11.5512C14.1868 11.3333 14.7469 11.3333 15.867 11.3333H18.0082C18.4974 11.3333 18.7419 11.3333 18.9721 11.3885C19.1762 11.4375 19.3713 11.5183 19.5502 11.628C19.7521 11.7517 19.925 11.9246 20.2709 12.2705L22.3964 14.396C22.7423 14.7419 22.9153 14.9148 23.0389 15.1167C23.1486 15.2956 23.2294 15.4907 23.2784 15.6948C23.3337 15.925 23.3337 16.1696 23.3337 16.6587Z"
        stroke="#145FC2"
        strokeWidth="1.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="2"
        y="2"
        width="32"
        height="32"
        rx="16"
        stroke="#E8F1FE"
        strokeWidth="4"
      />
    </svg>
  )
}

export default FileIcon
