import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endPoints } from "../utils/constants/endPoints";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { message } from "antd";
import { CreateMinesModel } from "../utils/models/CreateMinesModel";
import { CreateFossilModel2 } from "../utils/models/CreateFossilModel2";

export function useCreateFossil() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, CreateFossilModel2>(async (req) => {
    const res = await $api.post(endPoints.QAZILMA, req)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
      message.success("Muvaffaqqiyatli")
    }
  })
}

export function useCreateMines() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, CreateMinesModel[]>(async (req) => {
    const res = await $api.post(endPoints.QAZILMA_PRODUCTS, req, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
    }
  })
}

export function useUpdateMines() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, CreateMinesModel>(async (req) => {
    const res = await $api.patch(endPoints.QAZILMA_PRODUCTS_UPDATE, req, {
      headers: {
        "Content-Type": "application/json"
      }
    })
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
    }
  })
}

export function useUpdateFossil(id: number) {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, CreateFossilModel2>(async (req) => {
    const res = await $api.patch(endPoints.QAZILMA + id + "/", req)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
      message.success("Muvaffaqqiyatli")
    }
  })
}

export function useDeleteFossil() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(`${endPoints.QAZILMA}${id}/`)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
      message.success("Muvaffaqqiyatli")
    }
  })
}

export function useSendFilePath() {
  return useMutation<SuccessRes, ErrorRes, FormData>(async (req) => {
    const res = await $api.post(endPoints.QAZILMA_FILE, req, {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    })
    return res.data
  })
}

export function useUpdateQazilmaProduct() {
  const qc = useQueryClient()
  return useMutation(async (id: number) => {
    const res = await $api.patch(endPoints.QAZILMA_PRODUCT + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
      message.success("Muvaffaqqiyatli")
    }
  })
}

export function useDeleteQazilmaProduct() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(endPoints.QAZILMA_PRODUCT + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.QAZILMA])
      qc.invalidateQueries([queryKeys.PRODUCTS])
      message.success("Muvaffaqqiyatli")
    }
  })
}