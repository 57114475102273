import React, { useState } from "react"
import { Pagination, Table } from "antd"
import { useProducts } from "./services/queries"
import { ProductsModel } from "./utils/models/ProductsModel"
import { useQueryParam } from "../../hooks/useQueryParams"
import { useDeleteProduct } from "./services/mutations"
import ProductDrawer from "./components/product-drawer/ProductDrawer"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import BodyLayout from "../../common/body-layout/BodyLayout"
import itemRender from "../../utils/helpers/ItemReader"

import styles from "./product.module.scss"

const Index: React.FC = () => {
  const { searchParamsString, appendMultipleDifferent } = useQueryParam()
  const { data } = useProducts(searchParamsString)
  const [openDrawer, setOpenDrawer] = useState(false)
  const [drawerData, setDrawerData] = useState<ProductsModel>()
  const deleteProduct = useDeleteProduct()

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  //handleViewData
  const handleViewData = (render: ProductsModel) => {
    setDrawerData(render)
  }

  //handleDelete
  const handleDelete = (id: number) => {
    return deleteProduct.mutateAsync(id)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Nomi",
      key: "name",
      render: (items: ProductsModel) => (
        <div className={styles.productName}>
          {!items.status && <div className={styles.productName_dot}></div>}
          <span>{items.name}</span>
        </div>
      ),
      width: 448,
    },
    {
      title: "Kompaniya",
      dataIndex: "company",
      key: "company",
      width: 180,
    },
    {
      title: "Izoh",
      dataIndex: "comment",
      key: "comment",
    },
    {
      title: "",
      key: "actions",
      render: (render: ProductsModel) => (
        <div className={styles.productTable_actions}>
          <span
            className={styles.productTable_actions_view}
            onClick={() => {
              handleViewData(render)
              handleOpenDrawer()
            }}
          >
            Ko'rish
          </span>
          <DeleteConfirm
            text={`${render?.name} nomli mahsulotni o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(render?.id)}
            isLoading={deleteProduct?.isLoading}
          >
            <span className={styles.productTable_actions_delete}>O'chirish</span>
          </DeleteConfirm>
        </div>
      ),
    },
  ]

  return (
    <BodyLayout title="Mahsulotlar">
      <Table
        pagination={false}
        columns={columns}
        dataSource={data?.data}
        className={styles.productTable}
      />
      <ProductDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        drawerData={drawerData}
        setDrawerData={setDrawerData}
      />
      <Pagination
        onChange={(page) => changePage(page, 10)}
        current={data?.current_page}
        total={data?.total}
        itemRender={itemRender}
        hideOnSinglePage
      />
    </BodyLayout>
  )
}

export default Index
