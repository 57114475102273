import React, { useState } from "react"
import SearchIcon from "../../../../assets/icons/SearchIcon"
import PlusIcon from "../../../../assets/icons/PlusIcon"
import DeleteConfirm from "../../../../common/delete-confirm/DeleteConfirm"
import AdminCreateDrawer from "../drawers/AdminCreateDrawer"
import itemRender from "../../../../utils/helpers/ItemReader"
import { Button, Input, Skeleton, Table } from "antd"
import { useSettingsAdmin } from "../../services/queries"
import { AdminModel } from "../../utils/models/adminModel"
import { useDeleteSettingsAdmin } from "../../services/mutation"

import styles from "./admins.module.scss"
import { useQueryParam } from "../../../../hooks/useQueryParams"

const AdminsTable = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<AdminModel | undefined>()
  const { searchParams, searchParamsString, appendMultipleDifferent } =
    useQueryParam<any, any>()
  const { data, isLoading } = useSettingsAdmin(searchParamsString)
  const deleteAdmin = useDeleteSettingsAdmin()
  const [search, setSearch] = useState(searchParams.search)
  const changeSearch = (value: string) => {
    setSearch(value)
    appendMultipleDifferent(["search"], [value])
  }

  //handleDelete
  const handleDelete = (id: number) => {
    return deleteAdmin.mutateAsync(id)
  }

  //handleOpenDrawer
  const handleOpenDrawer = () => {
    setOpenDrawer(true)
  }

  //handleUpdate
  const handleUpdate = (render: AdminModel) => {
    setModalData(render)
    handleOpenDrawer()
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Ismi",
      key: "name",
      render: (render: AdminModel) => (
        <div className={styles.name}>
          <div>
            {render?.image ? (
              <img src={`${process.env.REACT_APP_API_URL}${render?.image}`} />
            ) : (
              <div className={styles.name_avatar}>
                {render?.FIO.substring(0, 1).toUpperCase()}
              </div>
            )}
          </div>
          <span>{render.FIO}</span>
        </div>
      ),
      width: 496,
    },
    {
      title: "Telefon raqami",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "",
      key: "actions",
      render: (render: AdminModel) => (
        <div className={styles.adminTable_actions}>
          <span onClick={() => handleUpdate(render)}>Tahrirlash</span>
          <DeleteConfirm
            text={`${render.FIO} ni o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(render.id)}
            isLoading={deleteAdmin.isLoading}
          >
            <span>O'chirish</span>
          </DeleteConfirm>
        </div>
      ),
    },
  ]
  return (
    <>
      <div className={styles.adminTable}>
        <div className={styles.adminTable_header}>
          <Input
            placeholder="Qidirmoq"
            prefix={<SearchIcon />}
            value={search}
            onChange={(e) => changeSearch(e.target.value)}
          />
          <Button onClick={handleOpenDrawer}>
            <PlusIcon />
            <span>Yangi qo'shish</span>
          </Button>
        </div>
        {!isLoading ? (
          <Table
            columns={columns}
            dataSource={data?.data}
            pagination={{
              onChange: (page) => changePage(page, 10),
              current: data?.current_page,
              total: data?.total,
              itemRender: itemRender,
              hideOnSinglePage: true,
            }}
          />
        ) : (
          <Skeleton active avatar />
        )}
      </div>
      <AdminCreateDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        modalData={modalData}
        setModalData={setModalData}
      />
    </>
  )
}

export default AdminsTable
