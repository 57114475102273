import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { NewsModel } from "../utils/models/newsModel";
import { EventsModel } from "../utils/models/eventsModel";
import { PaginationModel } from "../../../utils/models/PaginationModel";

export function useGetYangiliklar(searchParams: string) {

  return useQuery<PaginationModel<NewsModel>>
    ([queryKeys.YANGILIK, searchParams], async () => {
      const res = await $api.get(endpoints.YANGILIK + searchParams)
      return res.data
    })
}

export function useGetEvents(searchParams: string) {

  return useQuery<PaginationModel<EventsModel>>
    ([queryKeys.EVENTS, searchParams], async () => {
      const res = await $api.get(endpoints.EVENTS + searchParams)
      return res.data
    })
}