import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endPoints } from "../utils/constants/endPoints";
import { CityModel } from "../utils/models/CityModel";
import { PaginationModel } from "../../../utils/models/PaginationModel";
import { FossilModel } from "../utils/models/FossilsModel";

export function useGetCities() {
  return useQuery<CityModel[]>([queryKeys.CITIES], async () => {
    const res = await $api.get(endPoints.CITIES)
    return res.data
  })
}

export function useGetFossils(searchParams: string) {
  return useQuery<PaginationModel<FossilModel>>([queryKeys.QAZILMA, searchParams], async () => {
    const res = await $api.get(endPoints.QAZILMA + searchParams)
    return res.data
  })
}

export function useGetAllProducts(id: number) {
  return useQuery([queryKeys.PRODUCTS], async () => {
    const res = await $api.get(endPoints.QAZILMA_PRODUCTS + id)
    return res.data
  })
}