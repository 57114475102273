import { Form, Input } from "antd"
import React from "react"

import styles from "../admins/admins.module.scss"

type Props = {
  language: string
  label: string
}

const EditLanguage: React.FC<Props> = ({ language, label }) => {
  return (
    <div className={styles.incomeDrawer_editing}>
      <Form.Item
        label={label}
        name={`name_${language}`}
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
    </div>
  )
}

export default EditLanguage
