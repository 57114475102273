import { useMutation, useQueryClient } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { ErrorRes, SuccessRes } from "../../../utils/models/responseModel";
import { FaqClassModel, FaqClassUpdateModal } from "../utils/models/FaqClassModel";
import { queryKeys } from "../utils/constants/queryKeys";
import { FaqModel } from "../utils/models/FaqModel";
import { message } from "antd";

export function useCreateFaqClass() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FaqClassModel>
    (async (req) => {
      const res = await $api.post(endpoints.FAQ_CLASS, req)
      return res.data
    }, {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.FAQ_CLASS])
        message.success('Muvafaqqiyatli');
      },
      onError: () => {
        message.error("Xatolik")
      }
    })
}

export function useCreateFaq() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FaqModel>(async (req) => {
    const res = await $api.post(endpoints.FAQ, req)
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.FAQ])
      qc.invalidateQueries([queryKeys.FAQ_CLASS])
      message.success('Muvafaqqiyatli');
    },
    onError: () => {
      message.error("Xatolik")
    }
  })
}

export function useUpdateFaq(id: number | undefined) {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FaqModel>
    (async (req) => {
      const res = await $api.put(endpoints.FAQ + id + "/", req)
      return res.data
    }, {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.FAQ_CLASS])
        message.success('Muvafaqqiyatli');
      },
      onError: () => {
        message.error("Xatolik")
      }
    })
}

export function useUpdateFaqClass(id: number | undefined) {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, FaqClassUpdateModal>
    (async (req) => {
      const res = await $api.put(endpoints.FAQ_CLASS + id + "/", req)
      return res.data
    }, {
      onSuccess: () => {
        qc.invalidateQueries([queryKeys.FAQ_CLASS])
        message.success('Muvafaqqiyatli');
      },
      onError: () => {
        message.error("Xatolik")
      }
    })
}

export function useDeleteFaq() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(endpoints.FAQ + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.FAQ])
      qc.invalidateQueries([queryKeys.FAQ_CLASS])
      message.success('Muvafaqqiyatli');
    },
    onError: () => {
      message.error("Xatolik")
    }
  })
}

export function useDeleteFaqClass() {
  const qc = useQueryClient()
  return useMutation<SuccessRes, ErrorRes, number>(async (id) => {
    const res = await $api.delete(endpoints.FAQ_CLASS + id + "/")
    return res.data
  }, {
    onSuccess: () => {
      qc.invalidateQueries([queryKeys.FAQ_CLASS])
      message.success('Muvafaqqiyatli');
    },
    onError: () => {
      message.error("Xatolik")
    }
  })
}