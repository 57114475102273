import React, { useState } from "react"
import { Skeleton, Table } from "antd"
import { useInvestments } from "./service/queries"
import { useDeleteInvestments } from "./service/mutation"
import { SubInvestModels } from "./utils/models/subInvestModel"
import { useQueryParam } from "../../hooks/useQueryParams"
import { InvestmentsModel } from "./utils/models/investmentsModel"
import itemRender from "../../utils/helpers/ItemReader"
import InvestDrawer from "./components/drawer/InvestDrawer"
import BodyLayout from "../../common/body-layout/BodyLayout"
import AddInvestDrawer from "./components/drawer/AddInvestDrawer"
import IncomeInvestDrawer from "./components/drawer/IncomeInvestDrawer"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"

import styles from "./investments.module.scss"

const Index: React.FC = () => {
  const [open, setOpen] = useState(false)
  const [incomeOpen, setIncomeOpen] = useState(false)
  const [investId, setInvestId] = useState<number>()
  const [addOpen, setAddOpen] = useState(false)
  const [modalData, setModalData] = useState<InvestmentsModel | undefined>()
  const [subData, setSubData] = useState<SubInvestModels[]>()
  const [subModalData, setSubModalData] = useState<SubInvestModels | undefined>()
  const [balance, setBalance] = useState(0)
  const { appendMultipleDifferent, searchParamsString } = useQueryParam()
  const { data, isLoading } = useInvestments(searchParamsString)
  const deleteInvestments = useDeleteInvestments()

  //handleOpenViewInvesments
  const showDriwer = () => {
    setOpen(true)
  }

  //handleOpenCreateInvestments
  const openIncomeDrawer = () => {
    setIncomeOpen(true)
  }

  //handleOpenAddInvestDrawer
  const openAddDrawer = () => {
    setAddOpen(true)
  }

  //handleDeleteInvestments
  const handleDeleteInvestments = (id: number) => {
    return deleteInvestments.mutateAsync(id)
  }

  //handleUpdate
  const handleUpdate = (render: InvestmentsModel) => {
    setModalData(render)
    openIncomeDrawer()
  }

  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  const columns = [
    {
      title: "Nomi",
      dataIndex: "name_uz",
      key: "name_uz",
      render: (text: string) => (
        <span className={styles.investmentsTable_name}>{text}</span>
      ),
    },
    {
      title: "Kategoriyalar",
      key: "category",
      render: (render: InvestmentsModel) => <span>{render.category.uz}</span>,
      width: 300,
    },
    {
      title: "Investitsiya summasi",
      key: "summa",
      render: (render: InvestmentsModel) => (
        <span>{Number(render?.summa).toLocaleString("ru")}</span>
      ),
      width: 300,
    },
    {
      title: "Kiritilgan summa",
      key: "endSum",
      render: (render: InvestmentsModel) => (
        <span>{Number(render?.balans).toLocaleString("ru")}</span>
      ),
      width: 300,
    },
    {
      title: "",
      key: "actions",
      render: (render: InvestmentsModel) => {
        return (
          <div className={styles.investmentsTable_actions}>
            <span
              onClick={() => {
                showDriwer()
                setBalance(render?.balans)
                setInvestId(render?.id)
                setSubData(render?.invest_sub)
              }}
            >
              Ko'rish
            </span>
            <DeleteConfirm
              text={`${render.name_uz} ni o'chirmoqchimisiz?`}
              onConfirm={() => handleDeleteInvestments(render.id)}
              isLoading={deleteInvestments.isLoading}
            >
              <span className={styles.investmentsTable_actions_delete}>
                O'chirish
              </span>
            </DeleteConfirm>
            <span onClick={() => handleUpdate(render)}>Tahrirlash</span>
          </div>
        )
      },
    },
  ]

  return (
    <BodyLayout
      title="Investitsiyalar"
      createButton={{
        text: "Yangi qo'shish",
        onCreate: openIncomeDrawer,
      }}
    >
      {!isLoading ? (
        <Table
          rowKey={(prev) => prev.id}
          className={styles.investmentsTable}
          columns={columns}
          dataSource={data?.data}
          pagination={{
            onChange: (page) => changePage(page, 15),
            current: data?.current_page,
            total: data?.total,
            itemRender: itemRender,
            hideOnSinglePage: true,
          }}
        />
      ) : (
        <Skeleton active />
      )}
      <InvestDrawer
        open={open}
        setOpen={setOpen}
        openAddDrawer={openAddDrawer}
        balance={balance}
        subData={subData}
        setSubModalData={setSubModalData}
      />
      <IncomeInvestDrawer
        incomeOpen={incomeOpen}
        setIncomeOpen={setIncomeOpen}
        setModalData={setModalData}
        modalData={modalData}
      />
      <AddInvestDrawer
        investId={investId}
        setAddOpen={setAddOpen}
        addOpen={addOpen}
        setSubModalData={setSubModalData}
        subModalData={subModalData}
        setOpen={setOpen}
      />
    </BodyLayout>
  )
}

export default Index
