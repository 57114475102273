import { Button, Drawer, Form, Input } from "antd"
import React, { useEffect } from "react"
import CloseIcon from "../../../../assets/icons/CloseIcon"

import styles from "../../../offer/components/offer-drawer/offerDrawer.module.scss"
import { ProductsModel } from "../../utils/models/ProductsModel"
import { useChangeStatus } from "../../services/mutations"

type Props = {
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  openDrawer: boolean
  setDrawerData: React.Dispatch<React.SetStateAction<ProductsModel | undefined>>
  drawerData: ProductsModel | undefined
}

const ProductDrawer: React.FC<Props> = ({
  setOpenDrawer,
  openDrawer,
  drawerData,
}) => {
  const [form] = Form.useForm()
  const updateStatus = useChangeStatus(drawerData?.id)

  const onFinish = (values: ProductsModel) => {
    values.status = true
    updateStatus.mutateAsync(values).then(handleClose)
  }

  //handleClose
  const handleClose = () => {
    setOpenDrawer(false)
  }

  useEffect(() => {
    if (drawerData) {
      form.setFieldsValue(drawerData)
    }
  }, [drawerData])

  return (
    <Drawer
      open={openDrawer}
      onClose={handleClose}
      width={544}
      className={styles.drawer}
      title={
        <div className={styles.drawerTitle}>
          <span>Mahsulot qo'shish</span>
          <CloseIcon onClick={handleClose} />
        </div>
      }
    >
      <Form
        layout="vertical"
        form={form}
        className={styles.offerForm}
        onFinish={onFinish}
      >
        <Form.Item label="Nomi" name="name">
          <Input disabled />
        </Form.Item>
        <Form.Item label="Kompaniya" name="company">
          <Input disabled />
        </Form.Item>
        <p className={styles.drawerIzoh}>Izoh</p>
        <div className={styles.drawerComment}>
          <div className={styles.drawerComment_file}>
            {drawerData?.files &&
              drawerData?.files?.map((item) => (
                <img src={`${process.env.REACT_APP_API_URL}${item}`} />
              ))}
          </div>
          <Form.Item name="comment">
            <Input.TextArea disabled />
          </Form.Item>
        </div>
        <div className={styles.btns}>
          <Button onClick={handleClose}>Bekor qilish</Button>
          <Button htmlType="submit" loading={updateStatus.isLoading}>
            {drawerData?.status === true ? "O'zgartirish" : "Tasdiqlash"}
          </Button>
        </div>
      </Form>
    </Drawer>
  )
}

export default ProductDrawer
