import React from "react"

type Props = {
  color?: string
}

const GasStationIcon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4 22V5C4 3 5.34 2 7 2H15C16.66 2 18 3 18 5V22H4Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.5 22H19.5"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.89 9.99998H13.12C14.16 9.99998 15.01 9.49999 15.01 8.10999V6.87999C15.01 5.48999 14.16 4.98999 13.12 4.98999H8.89C7.85 4.98999 7 5.48999 7 6.87999V8.10999C7 9.49999 7.85 9.99998 8.89 9.99998Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 13H10"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 16.01L22.5 16V10L20.5 9"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default GasStationIcon
