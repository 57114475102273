import axios from "axios";
import { LocalStorage } from "./LocalStorage";
import { TOKEN } from "../utils/constants/localStorageKeys";
import { message } from "antd";
import { rootPaths } from "../routing/rootPaths";

const $api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${LocalStorage.get(TOKEN)}`,
    "Content-Type": "multipart/form-data"
  },
})


$api.interceptors.response.use(undefined, (error) => {
  if (error.message === 'Network Error') {
    message.error("Internet tarmog'iga ulanishda xatolik yuz berdi", 5)
  }
  if (
    error.response?.status === 403 &&
    window.location.pathname !== rootPaths.INVESTMENTS_INNOVATIONS
  ) {
    localStorage.removeItem("token")
    window.location.href = rootPaths.INVESTMENTS_INNOVATIONS
  }
  return Promise.reject(error)
})

export default $api