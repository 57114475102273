import React, { useState } from "react"
import { Skeleton, Table } from "antd"
import { useGetAdministration } from "./services/queries"
import { AdministrationModel } from "./utils/models/AdministrationModel"
import { useDeleteAdministration } from "./services/mutation"
import BodyLayout from "../../common/body-layout/BodyLayout"
import DeleteConfirm from "../../common/delete-confirm/DeleteConfirm"
import itemRender from "../../utils/helpers/ItemReader"
import ManagementDrawer from "./components/drawers/ManagementDrawer"

import styles from "./management.module.scss"
import { useQueryParam } from "../../hooks/useQueryParams"

const Index: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [modalData, setModalData] = useState<AdministrationModel | undefined>()
  const deleteAdministration = useDeleteAdministration()
  const { searchParamsString, appendMultipleDifferent } = useQueryParam()
  const { data, isLoading } = useGetAdministration(searchParamsString)

  const handleDelete = (id: number) => {
    return deleteAdministration.mutateAsync(id)
  }

  const handleOpen = () => {
    setOpenDrawer(true)
  }

  //handlePagination
  const changePage = (page: number, size: number) => {
    appendMultipleDifferent(["page", "size"], [page, size])
  }

  //handleUpdate
  const handleUpdate = (item: AdministrationModel) => {
    setModalData(item)
    handleOpen()
  }

  const columns = [
    {
      title: "Ismi",
      key: "name",
      width: 496,
      render: (item: AdministrationModel) => (
        <div className={styles.managementName}>
          <div className={styles.managementName_avatar}>
            {item?.image ? (
              <img
                src={`${process.env.REACT_APP_API_URL}${item?.image}`}
                alt="error"
              />
            ) : (
              item.name_uz.substring(0, 1)
            )}
          </div>
          <span>{item.name_uz}</span>
        </div>
      ),
    },
    {
      title: "Lavozimi",
      key: "position",
      width: 496,
      render: (item: AdministrationModel) => <span>{item.lavozim_uz}</span>,
    },
    {
      title: "",
      key: "action",
      render: (item: AdministrationModel) => (
        <div className={styles.managementActions}>
          <DeleteConfirm
            text={`${item?.name_uz} ni o'chirmoqchimisiz?`}
            onConfirm={() => handleDelete(item?.id)}
            isLoading={deleteAdministration?.isLoading}
          >
            <span className={styles.managementActions_delete}>O'chirish</span>
          </DeleteConfirm>
          <span
            className={styles.managementActions_update}
            onClick={() => handleUpdate(item)}
          >
            Tahrirlash
          </span>
        </div>
      ),
    },
  ]

  return (
    <BodyLayout
      title="Ma'muriyat"
      createButton={{ text: "Yangi yaratish", onCreate: handleOpen }}
    >
      {!isLoading ? (
        <Table
          columns={columns}
          dataSource={data?.data}
          pagination={{
            itemRender: itemRender,
            onChange: (page) => changePage(page, 10),
            hideOnSinglePage: true,
            current: data?.current_page,
            total: data?.total,
          }}
        />
      ) : (
        <Skeleton active avatar />
      )}
      <ManagementDrawer
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        modalData={modalData}
        setModalData={setModalData}
      />
    </BodyLayout>
  )
}

export default Index
