import { LoginResModel } from './../utils/constants/models/loginModel';
import { useMutation } from "@tanstack/react-query";
import $api from "../../../services/RequestService";
import { endpoints } from "../utils/constants/endpoints";
import { LoginBodyModel } from "../utils/constants/models/loginModel";
import { LocalStorage } from "../../../services/LocalStorage";
import { REFRESH_TOKEN, TOKEN } from "../../../utils/constants/localStorageKeys";
import { rootPaths } from "../../../routing/rootPaths";
import { ErrorRes, SuccessRes } from '../../../utils/models/responseModel';
import { message } from 'antd';

export function useLogin() {
    return useMutation<SuccessRes<LoginResModel>, ErrorRes, LoginBodyModel>(
        async (data) => {
            const res = await $api.post(endpoints.LOGIN, data)
            return res.data
        }, {
        onSuccess: (res) => {
            LocalStorage.set(TOKEN, res.access)
            LocalStorage.set(REFRESH_TOKEN, res.refresh);
            // navigate(rootPaths.INVESTMENTS)
            window.location.replace(rootPaths.INVESTMENTS)
            message.success('Muvafaqqiyatli kirdingiz!');
        },
        onError: () => {
            message.error("Tizimga kirishda xatolik")
        }
    })
}