import React, { useState } from "react"
import { Button, Modal } from "antd"
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"
import { rootLinks } from "./rootLinks"
import { rootPaths } from "../../routing/rootPaths"
import { useGetProfile } from "../../features/profile/services/queries"
import LogoutIcon from "../../assets/icons/LogoutIcon"
import LogoIcon from "../../assets/icons/logo"

import styles from "./mainLayout.module.scss"

const MainLayout: React.FC = () => {
  const { data, isLoading } = useGetProfile()
  const location = useLocation()
  const navigate = useNavigate()
  const [logout, setLogout] = useState(false)
  const handleCancel = () => {
    setLogout(false)
  }
  const handleOpen = () => {
    setLogout(true)
  }
  const handleLogout = () => {
    localStorage.clear()
    navigate(rootPaths.AUTH)
  }

  //handleNavigate
  const handleNavigate = () => {
    navigate({
      pathname: rootPaths.PROFILE,
    })
  }

  // active
  const isActive = (path: string) => {
    if (location.pathname === path) return styles.active
    return ""
  }
  return (
    <div className={styles.mainLayout}>
      <div className={styles.mainLayout_navbar}>
        <div className={styles.mainLayout_navbar_top}>
          <LogoIcon />
        </div>
        <div className={styles.mainLayout_navbar_body}>
          {rootLinks?.map((item) => (
            <Link to={item?.path} key={item?.path} className={isActive(item.path)}>
              <item.icon />
              <span>{item.text}</span>
            </Link>
          ))}
        </div>
        <div className={styles.mainLayout_navbar_profile}>
          <div
            className={styles.mainLayout_navbar_profile_top}
            onClick={handleNavigate}
          >
            <span>{data?.FIO}</span>
            <span>{data?.username}</span>
          </div>
          <LogoutIcon onClick={handleOpen} />
        </div>
      </div>
      <Modal onCancel={handleCancel} open={logout} footer={false}>
        <div className={styles.out}>
          <LogoutIcon />
          <p className={styles.logout}>{data?.FIO} accountdan chiqmoqchimisiz?</p>
        </div>
        <div className={styles.btns}>
          <Button onClick={handleCancel}>Bekor qilish</Button>
          <Button onClick={handleLogout} loading={isLoading}>
            Chiqish
          </Button>
        </div>
      </Modal>
      <Outlet />
    </div>
  )
}

export default MainLayout
