import { Form, Input } from "antd"
import React from "react"
type Props = {
  language: string
}

const TabClassItem: React.FC<Props> = ({ language }) => {
  return (
    <Form.Item
      label="Faq nomi"
      name={`name_${language}`}
      rules={[{ required: true, message: "" }]}
    >
      <Input />
    </Form.Item>
  )
}

export default TabClassItem
