import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../utils/constants/queryKeys";
import $api from "../../../services/RequestService";
import { endPoints } from "../utils/constants/endPoints";
import { PaginationModel } from "../../../utils/models/PaginationModel";
import { IndustryModel } from "../utils/models/IndustryModel";

export function useGetIndustry(searchParams: string) {
  return useQuery<PaginationModel<IndustryModel>>([queryKeys.INDUSTRY, searchParams], async () => {
    const res = await $api.get(endPoints.INDUSTRY + searchParams)
    return res.data
  })
}