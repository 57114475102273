import { Form, Input } from "antd"
import React from "react"

type Props = {
  language: string
}

const TabItem: React.FC<Props> = ({ language }) => {
  return (
    <div>
      <Form.Item
        label="Ismi"
        name={`name_${language}`}
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Lavozimi"
        name={`lavozim_${language}`}
        rules={[{ required: true, message: "" }]}
      >
        <Input />
      </Form.Item>
    </div>
  )
}

export default TabItem
