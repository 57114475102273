import React from "react"

type Props = {
  color?: string
}

const PercentageIcon: React.FC<Props> = ({ color = "#292D32" }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.5 2H15.5C20.5 2 22.5 4 22.5 9V15C22.5 20 20.5 22 15.5 22H9.5C4.5 22 2.5 20 2.5 15V9C2.5 4 4.5 2 9.5 2Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.07031 15.27L15.6103 8.72998"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.48001 10.37C10.1593 10.37 10.71 9.81936 10.71 9.14005C10.71 8.46073 10.1593 7.91003 9.48001 7.91003C8.8007 7.91003 8.25 8.46073 8.25 9.14005C8.25 9.81936 8.8007 10.37 9.48001 10.37Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.02 16.09C16.6993 16.09 17.25 15.5393 17.25 14.86C17.25 14.1807 16.6993 13.63 16.02 13.63C15.3407 13.63 14.79 14.1807 14.79 14.86C14.79 15.5393 15.3407 16.09 16.02 16.09Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PercentageIcon
