import React from "react"

const OfferIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.30035 18.0401V16.8801C6.00035 15.4901 4.11035 12.7801 4.11035 9.90005C4.11035 4.95005 8.66035 1.07005 13.8004 2.19005C16.0604 2.69005 18.0404 4.19005 19.0704 6.26005C21.1604 10.4601 18.9604 14.9201 15.7304 16.8701V18.0301C15.7304 18.3201 15.8404 18.9901 14.7704 18.9901H9.26035C8.16035 19.0001 8.30035 18.5701 8.30035 18.0401Z"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 22C10.79 21.35 13.21 21.35 15.5 22"
        stroke="#667085"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OfferIcon
